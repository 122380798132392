import {
  BackButtonV2,
  PageHeaderV2,
  RadioButton,
  styleForFullExpansion,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import { builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import JSZip from 'jszip';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getBsContract } from '../../__generated/admin/bs-contract/bs-contract';
import { getContractUser } from '../../__generated/admin/contract-user/contract-user';
import {
  ImportContractsFromCsvForAdmin200,
  ImportContractsFromCsvForAdminBody,
  ImportContractUsersFromCsvForAdmin200,
  ImportContractUsersFromCsvForAdminBody
} from '../../__generated/model';
import DefaultModal from '../../components/modal/DefaultModal';
import Screen from '../../components/screen/Screen';
import { ERROR, SUCCESS } from '../../constants/snackbar-const';
import {
  convertContractUserFields,
  createLineErrorMessage
} from '../../converters/contracts/import-contract-csv-converter';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import { csvConverter } from '../../utils/csv-converter.util';
import {
  CONTRACT_IMPORT_TYPE,
  CONTRACT_IMPORT_TYPE_LABELS,
  ContractImportType,
  ERROR_FILE_TYPE,
  FILE_SIZE_10MB,
  FILE_TYPES,
  FileKind,
  FileStatus,
  initializeFiles,
  initializeImportStatus,
  Process
} from './contract-import/import-contract-import-type';
import { ImportContractResultComponent } from './contract-import/ImportContractResultComponent';
import { ImportContractTemplateDownloadComponent } from './contract-import/ImportContractTemplateDownloadComponent';
import { ImportContractUploaderComponent } from './contract-import/ImportContractUploaderComponent';
import { ImportContractUserUploaderComponent } from './contract-import/ImportContractUserUploaderComponent';

interface P {
  base: BaseDto;
  user: User;
}

const TEMPLATE_CSV_FILE_PATH = [
  `/templates/csv/contract.csv`,
  `/templates/csv/contract_detail.csv`,
  `/templates/csv/contract_user.csv`
];

const ImportContractScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const unmountRef = useUnmountRef();
  const { openPath } = usePath();
  const { openSnackbar } = useSnackbarV2();

  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);

  const [files, setFiles] = useSafeState<Record<FileKind, File | null>>(unmountRef, initializeFiles);
  const [errorBlobs, setErrorBlobs] = useSafeState<Record<FileKind, Blob | null>>(unmountRef, initializeFiles);
  const [importStatus, setImportStatus] = useSafeState<Record<FileKind, Process>>(unmountRef, initializeImportStatus);

  const [isOpenModal, setIsOpenModal] = useSafeState<boolean>(unmountRef, false);

  const [importType, setImportType] = useSafeState<ContractImportType>(unmountRef, CONTRACT_IMPORT_TYPE.CONTACT);

  const isHappenErrors = useMemo((): boolean => {
    return !!errorBlobs.contract || !!errorBlobs.contractDetails || !!errorBlobs.contractUsers;
  }, [errorBlobs]);

  const isProcessedExpression = useMemo(() => {
    return (
      importStatus.contract !== 'unprocessed' ||
      importStatus.contractDetails !== 'unprocessed' ||
      importStatus.contractUsers !== 'unprocessed'
    );
  }, [importStatus]);

  const handleButtonClicked = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]));
  }, [openPath, base]);

  const handleFileChanged = useSafeCallback(
    async (file: File, type: FileKind): Promise<boolean> => {
      setFiles(prev => ({
        ...prev,
        [type]: file
      }));
      return true;
    },
    [setFiles]
  );

  const removedAttachFiles = useSafeCallback(
    (keys: FileKind[]) => {
      setFiles(prev => {
        const newFiles = { ...prev };
        keys.forEach(key => {
          newFiles[key] = null;
        });
        return newFiles;
      });
    },
    [setFiles]
  );

  const setImportStatusTo = useSafeCallback(
    (keys: FileKind[], status: Process) => {
      setImportStatus(prev => {
        const newStatus = { ...prev };
        keys.forEach(key => {
          newStatus[key] = status;
        });
        return newStatus;
      });
    },
    [setImportStatus]
  );

  const downloadErrorZipFile = useSafeCallback(async () => {
    const zip = new JSZip();

    for (const [fileKind, blob] of Object.entries(errorBlobs)) {
      if (blob) {
        try {
          const arrayBuffer = await blob.arrayBuffer();
          zip.file(`${fileKind}_errors.txt`, arrayBuffer, { binary: true });
        } catch (error) {
          console.error(`Error reading Blob ${fileKind}:`, error);
        }
      }
    }

    const content = await zip.generateAsync({ type: 'blob' });
    const url = window.URL.createObjectURL(content);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'contract_import_errors.zip';
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }, [errorBlobs]);

  const currentFileStatuses = useMemo((): FileStatus[] => {
    return FILE_TYPES.map(fileType => ({
      ...fileType,
      status: importStatus[fileType.key]
    }));
  }, [importStatus]);

  const callContractApi = useSafeCallback(async (): Promise<{ success: boolean; errorBlob?: Blob }> => {
    if (!files.contract || !files.contractDetails) return { success: true };

    const body = builder<ImportContractsFromCsvForAdminBody>()
      .userId(user.userId)
      .contractsCsv(new Blob([files.contract]))
      .contractDetailsCsv(new Blob([files.contractDetails]))
      .build();
    return await getBsContract()
      .importContractsFromCsvForAdmin(base.baseId, body)
      .then((response: { data: ImportContractsFromCsvForAdmin200 }) => {
        const { errors, result } = response.data;
        if (result) {
          return { success: true };
        }
        if (!result && hasLength(errors)) {
          const warnMessages = errors.map(e => createLineErrorMessage(e, convertContractUserFields)).join('\n');
          return {
            success: false,
            errorBlob: new Blob([warnMessages], { type: ERROR_FILE_TYPE })
          };
        }
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }, [base.baseId, files, user.userId]);

  const callContractUserApi = useSafeCallback(async (): Promise<{ success: boolean; errorBlob?: Blob }> => {
    if (!files.contractUsers) return { success: true };

    const body = builder<ImportContractUsersFromCsvForAdminBody>()
      .contractUsersCsv(new Blob([files.contractUsers]))
      .build();

    return await getContractUser()
      .importContractUsersFromCsvForAdmin(body, base.baseId)
      .then((response: { data: ImportContractUsersFromCsvForAdmin200 }) => {
        const { errors, result } = response.data;
        if (result) {
          return { success: true };
        }
        if (!result) {
          if (hasLength(errors)) {
            const warnMessages = errors.map(e => createLineErrorMessage(e, convertContractUserFields)).join('\n');
            return {
              success: false,
              errorBlob: new Blob([warnMessages], { type: ERROR_FILE_TYPE })
            };
            // システムエラーを想定した場合
          } else {
            return {
              success: false
            };
          }
        }
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }, [base.baseId, files.contractUsers]);

  const importContracts = useSafeCallback(async (): Promise<void> => {
    const { success, errorBlob } = await callContractApi();
    if (success) {
      openSnackbar('契約の取り込みに成功しました!', SUCCESS, 10000);
      removedAttachFiles(['contract', 'contractDetails']);
      setImportStatusTo(['contract', 'contractDetails'], 'success');
      return;
    }
    // invalid error
    if (!success && errorBlob) {
      setErrorBlobs(prev => ({ ...prev, contract: errorBlob ?? null }));
      setImportStatusTo(['contract', 'contractDetails'], 'failure');
      openSnackbar('ファイルの取り込みに失敗しました。時間を置いて再度リトライをお願いいたします', ERROR, 10000);
      return;
    }
    // system error
    if (!success && !errorBlob) {
      setImportStatusTo(['contract', 'contractDetails'], 'failure');
      openSnackbar(
        'ファイルの取り込みに失敗しました。お手数おかけいたしますが、システム管理者にお問合せください。',
        ERROR,
        10000
      );
      return;
    }
  }, [callContractApi, openSnackbar, removedAttachFiles, setErrorBlobs, setImportStatusTo]);

  const importContractUsers = useSafeCallback(async (): Promise<void> => {
    const { success, errorBlob } = await callContractUserApi();
    if (success) {
      openSnackbar('契約参加者の取り込みに成功しました!', SUCCESS, 10000);
      removedAttachFiles(['contractUsers']);
      setImportStatusTo(['contractUsers'], 'success');
      return;
    }
    // invalid error
    if (!success && errorBlob) {
      setErrorBlobs(prev => ({ ...prev, contract: errorBlob ?? null }));
      setImportStatusTo(['contractUsers'], 'failure');
      openSnackbar('ファイルの取り込みに失敗しました。時間を置いて再度リトライをお願いいたします', ERROR, 10000);
      return;
    }
    // system error
    if (!success && !errorBlob) {
      setImportStatusTo(['contractUsers'], 'failure');
      openSnackbar(
        'ファイルの取り込みに失敗しました。お手数おかけいたしますが、システム管理者にお問合せください。',
        ERROR,
        10000
      );
      return;
    }
  }, [callContractUserApi, openSnackbar, removedAttachFiles, setErrorBlobs, setImportStatusTo]);

  const importContractsCsv = useSafeCallback(async (): Promise<void> => {
    setErrorBlobs(initializeFiles);
    setImportStatusTo(['contract', 'contractDetails', 'contractUsers'], 'unprocessed');
    removedAttachFiles(['contract', 'contractDetails', 'contractUsers']);

    setIsLoading(true);

    switch (importType) {
      case CONTRACT_IMPORT_TYPE.CONTACT:
        return await importContracts();
      case CONTRACT_IMPORT_TYPE.CONTRACT_USER:
        return await importContractUsers();
    }
  }, [
    setErrorBlobs,
    setImportStatusTo,
    removedAttachFiles,
    setIsLoading,
    importType,
    importContracts,
    importContractUsers
  ]);

  const handleDownloadTemplateCSV = useSafeCallback(async () => {
    const zip = new JSZip();
    const promises = TEMPLATE_CSV_FILE_PATH.map(async filePath => {
      return await fetch(window.location.origin + filePath).then(async e => {
        const fileContent = await e.blob();
        const fileName = filePath.split('/').pop();
        if (fileName) {
          zip.file(fileName, fileContent);
        }
      });
    });
    await Promise.all(promises);
    const content = await zip.generateAsync({ type: 'blob' });
    const url = window.URL.createObjectURL(content);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'contract_templates.zip';
    document.body.appendChild(link);
    link.click();
    // Clean up Process
    link.remove();
    window.URL.revokeObjectURL(url);
  }, []);

  const handleDownloadPlans = useSafeCallback(async () => {
    const response = await getBsContract().fetchContractPlansForAdmin(base.baseId);
    const contractPlans = response.data.contractPlans;
    const csvData = await csvConverter.convertToCSV(contractPlans);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'contract_plans.csv';
    document.body.appendChild(link);
    link.click();
    // Clean up Process
    link.remove();
    window.URL.revokeObjectURL(url);
  }, [base.baseId]);

  const onImportData = useSafeCallback(async () => {
    setIsLoading(true);
    await importContractsCsv().finally(() => {
      setIsLoading(false);
      setIsOpenModal(false);
    });
  }, [importContractsCsv, setIsLoading, setIsOpenModal]);

  const changeImportType = useSafeCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      removedAttachFiles(['contract', 'contractDetails', 'contractUsers']);
      setImportType(e);
    },
    [removedAttachFiles, setImportType]
  );

  const ImortContractFile = useMemo(() => {
    switch (importType) {
      case CONTRACT_IMPORT_TYPE.CONTACT:
        return (
          <ImportContractUploaderComponent
            onContractFileChange={(file: File) => handleFileChanged(file, 'contract')}
            onContractDetailsFileChange={(file: File) => handleFileChanged(file, 'contractDetails')}
            onClick={() => setIsOpenModal(true)}
            files={{
              contract: files.contract,
              contractDetails: files.contractDetails
            }}
            isLoading={isLoading}
            maxSizeInBytes={FILE_SIZE_10MB}
          />
        );
      case CONTRACT_IMPORT_TYPE.CONTRACT_USER:
        return (
          <ImportContractUserUploaderComponent
            onContractUserFileChange={(file: File) => handleFileChanged(file, 'contractUsers')}
            onClick={onImportData}
            files={{
              contractUsers: files.contractUsers
            }}
            isLoading={isLoading}
            maxSizeInBytes={FILE_SIZE_10MB}
          />
        );
    }
  }, [
    files.contract,
    files.contractDetails,
    files.contractUsers,
    handleFileChanged,
    importType,
    isLoading,
    onImportData,
    setIsOpenModal
  ]);

  return (
    <Screen style={styleForFullExpansion} className='contract-import-screen' loading={isLoading} loadingType='circular'>
      <Container data-testid='contract-import-screen'>
        <BackButtonV2 label='戻る' onClick={handleButtonClicked} />
        <PageHeaderV2 title='契約の新規作成（CSVで一括作成）' />
        <Section>
          <Head>1. CSVファイルの作成</Head>
          <ImportContractTemplateDownloadComponent
            downloadTemplate={handleDownloadTemplateCSV}
            downloadPlans={handleDownloadPlans}
          />
        </Section>
        <Section>
          <Head>2. CSVファイルのアップロード</Head>
          <Description>アップロードするファイルの種類</Description>
          <RadioButton
            options={Object.values(CONTRACT_IMPORT_TYPE)}
            labels={CONTRACT_IMPORT_TYPE_LABELS}
            value={importType}
            onChange={changeImportType}
          />
          {ImortContractFile}
        </Section>
        <Section>
          <ImportContractResultComponent
            baseCode={base.baseCode}
            fileStatuses={currentFileStatuses}
            importType={importType}
            isHappenErrors={isHappenErrors}
            isProcessedExpression={isProcessedExpression}
            downloadErrorZipFile={downloadErrorZipFile}
          />
        </Section>
        <DefaultModal
          isOpen={isOpenModal}
          headerLabel={'契約インポートに関する注意事項'}
          rightButtonProps={{ label: 'インポートする', onClick: onImportData }}
          onClose={() => setIsOpenModal(false)}
        >
          <ModalContent>
            {'契約情報をインポートする前に、以下の重要な注意事項をご確認ください。\n\n' +
              '【自動メール送信について】\n' +
              '以下の条件を満たす場合、翌日午前9時に契約者へクレジットカード登録メールが自動送信されます：\n' +
              '・支払い方法がクレジットカードの契約者\n' +
              '・Stripe決済が有効化されている拠点\n\n' +
              '上記内容をご確認の上、インポートを実行する場合は「インポートする」ボタンをクリックしてください。'}
          </ModalContent>
        </DefaultModal>
      </Container>
    </Screen>
  );
});

ImportContractScreen.displayName = 'ImportContractScreen';
export default ImportContractScreen;

const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
`;

const Section = styled.section`
  background-color: ${themeV3.mixins.v3.color.object.white};
  padding: ${themeV3.mixins.v3.spacing * 2}px ${themeV3.mixins.v3.spacing * 3}px ${themeV3.mixins.v3.spacing * 3}px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
`;

const Head = styled.div`
  ${themeV3.mixins.v3.typography.title.xLarge};
  padding: 0 ${themeV3.mixins.v3.spacing}px ${themeV3.mixins.v3.spacing}px;
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};
`;

const Description = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
  font-weight: 700;
  line-height: ${themeV3.mixins.v3.spacing * 3}px;
  letter-spacing: 0.1px;
`;

const ModalContent = styled.div`
  white-space: pre-wrap;
`;
