import {
  ButtonV2,
  CircularLoader,
  InformationPanel,
  PageHeaderV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, BaseResourceCategory, DropInId, ResourceCategoryCode } from '@atomica.co/irori';
import { Id, Minute, Rate, Time } from '@atomica.co/types';
import { FIRST_INDEX, hasLength } from '@atomica.co/utils';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  FetchDropIn200DropIn,
  SearchDropInItems200DropInItemsItem,
  SearchDropInItems200DropInItemsItemAvailableTimesItem
} from '../../__generated/model';
import { getBsSpace } from '../../__generated/user/bs-space/bs-space';
import { toDropInCrowdingLevel } from '../../converters/drop-in-crowding-level-converter';
import { convertToAvailableTimes } from '../../converters/space-converter';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import SpaceCard from '../space/card/SpaceCard';
import { DropInConfirmScreenHistoryState } from './DropInConfirmScreen';

interface P {
  base: BaseDto;
}

const isDropInUnavailable = (
  availableTimes: SearchDropInItems200DropInItemsItemAvailableTimesItem[],
  crowdingRatio: Rate | undefined,
  isCurrentlyInUseSpace: boolean
): boolean => {
  if (!hasLength(availableTimes)) return false;

  const now = new Date();
  const currentDay = now.getDay();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  const parseUTCTimeToJTCMinutes = (timeStr: Time): Minute => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const jstHour = (hours + 9) % 24;
    return jstHour * 60 + minutes;
  };

  const isWithinAvailableTime = availableTimes.some(timeSlot => {
    const isDayAvailable = timeSlot.dayOfWeek === currentDay;

    if (!isDayAvailable) return false;

    const startTimeInMinutes = parseUTCTimeToJTCMinutes(timeSlot.startAt);
    let endTimeInMinutes = parseUTCTimeToJTCMinutes(timeSlot.endAt);

    if (startTimeInMinutes > endTimeInMinutes) endTimeInMinutes += 24 * 60;

    return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes <= endTimeInMinutes;
  });

  const isCapcityLimit = (crowdingRatio ?? 0) >= 100;

  return !isWithinAvailableTime || isCapcityLimit || isCurrentlyInUseSpace;
};

const DropInEntryScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { openBasePath, queryParams } = usePath();

  const unmountRef = useUnmountRef();
  const [dropInItems, setDropInItems] = useSafeState<SearchDropInItems200DropInItemsItem[]>(unmountRef);
  const [dropIn, setDropIn] = useSafeState<FetchDropIn200DropIn>(unmountRef);
  const [selectedDropInItemId, setSelectedDropInItemId] = useSafeState<Id>(unmountRef);
  const [selectedSpacePlanId, setSelectedSpacePlanId] = useSafeState<Id>(unmountRef);
  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);

  const packRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);

  const dropInId = useMemo(() => queryParams.dropInId, [queryParams]);

  const selectedDropItem = useMemo<SearchDropInItems200DropInItemsItem | undefined>(() => {
    const targetSpaceId = dropIn ? dropIn.spaceId : selectedDropInItemId;
    return dropInItems?.find(item => item.spaceId === targetSpaceId);
  }, [dropIn, dropInItems, selectedDropInItemId]);

  const baseResourceCategory = useMemo<BaseResourceCategory | undefined>(() => {
    return base.baseResourceCategories?.find(
      category => category.resourceCategoryDef?.categoryCode === ResourceCategoryCode.FREE_SPACE
    );
  }, [base.baseResourceCategories]);

  const filteredDropInItems = useMemo(
    () =>
      dropIn
        ? dropInItems?.filter(item => dropIn.spaceId === item.spaceId)
        : dropInItems?.filter(item => baseResourceCategory?.baseResourceCategoryId === item.baseResourceCategoryId),
    [baseResourceCategory, dropIn, dropInItems]
  );

  const getSelectedIds = useSafeCallback(
    (disabled: boolean): Id[] => {
      if (disabled) return [];
      if (dropIn) return [dropIn.spaceId];
      if (selectedDropInItemId) return [selectedDropInItemId];
      return [];
    },
    [dropIn, selectedDropInItemId]
  );

  const handleDropInItemIdSelected = useSafeCallback(
    (selectedDropInItemId: Id): void => {
      setSelectedDropInItemId(selectedDropInItemId);
      setSelectedSpacePlanId(undefined);
    },
    [setSelectedDropInItemId, setSelectedSpacePlanId]
  );

  const searchDropInItems = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    const response = await getBsSpace().searchDropInItems(base.baseId);
    setDropInItems(response.data.dropInItems);
    if (response.data.dropInItems.length === 1) {
      const dropInItem = response.data.dropInItems[FIRST_INDEX];
      if (isDropInUnavailable(dropInItem.availableTimes, dropInItem.crowdingRatio, dropInItem.isCurrentlyInUseSpace)) {
        return setIsLoading(false);
      }
      handleDropInItemIdSelected(dropInItem.spaceId);
      if (dropInItem.plans.length === 1) {
        setSelectedSpacePlanId(dropInItem.plans[FIRST_INDEX].spacePlanId);
      }
    }
    setIsLoading(false);
  }, [base.baseId, handleDropInItemIdSelected, setDropInItems, setIsLoading, setSelectedSpacePlanId]);

  const fetchDropIn = useSafeCallback(
    async (dropInId: DropInId): Promise<void> => {
      const response = await getBsSpace().fetchDropIn(base.baseId, dropInId);
      setDropIn(response.data.dropIn);
    },
    [base.baseId, setDropIn]
  );

  const openDropInConfirmScreen = useSafeCallback((): void => {
    const selectedDropPlan = selectedDropItem?.plans.find(plan => plan.spacePlanId === selectedSpacePlanId);

    openBasePath<DropInConfirmScreenHistoryState>(Path.DROP_IN_CONFIRM, {
      dropInItem: {
        spaceId: selectedDropItem?.spaceId,
        spaceName: selectedDropItem?.spaceName,
        spacePlanId: selectedDropPlan?.spacePlanId,
        spacePricingId: selectedDropPlan?.spacePricingId,
        planName: selectedDropPlan?.planName,
        totalPrice: selectedDropPlan?.totalPrice,
        taxRate: selectedDropPlan?.taxRate,
        minutes: selectedDropPlan?.minutes
      },
      beforeDropIn: dropIn
    });
  }, [
    dropIn,
    openBasePath,
    selectedDropItem?.plans,
    selectedDropItem?.spaceId,
    selectedDropItem?.spaceName,
    selectedSpacePlanId
  ]);

  useEffect(() => {
    searchDropInItems();
    if (dropInId) fetchDropIn(dropInId);
  }, [dropInId, fetchDropIn, searchDropInItems]);

  useEffect(() => {
    if (!selectedDropInItemId || dropInItems?.length === 1) return;
    packRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [dropInItems, selectedDropInItemId]);

  useEffect(() => {
    if (!selectedSpacePlanId || dropInItems?.length === 1) return;
    noteRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [dropInItems, selectedSpacePlanId]);

  return (
    <Container data-testid='drop-in-entry-screen'>
      <PageHeaderV2 title='ドロップイン' />
      {baseResourceCategory?.informationTitle && baseResourceCategory?.informationNote && (
        <InformationPanel isBold title={baseResourceCategory.informationTitle}>
          {baseResourceCategory.informationNote}
        </InformationPanel>
      )}
      <Content data-testid='select-space'>
        <TextWrapper>
          <Title>利用スペースの選択（1/2）</Title>
          <BodyLarge>ドロップイン利用したいスペースを選択してください。</BodyLarge>
        </TextWrapper>
        <DropInItems>
          {isLoading && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}
          {!isLoading &&
            hasLength(filteredDropInItems) &&
            filteredDropInItems.map((item, idx) => {
              const disabled = isDropInUnavailable(item.availableTimes, item.crowdingRatio, item.isCurrentlyInUseSpace);
              return (
                <SpaceCard
                  disabled={disabled}
                  key={`space-card-${idx}`}
                  id={item.spaceId}
                  selectedIds={getSelectedIds(disabled)}
                  handleClicked={handleDropInItemIdSelected}
                >
                  <CardBody>
                    <Title>{item.spaceName}</Title>
                    <LabelLarge>料金: ￥{item.minimumPrice.toLocaleString()} 〜</LabelLarge>
                    {item.crowdingRatio !== undefined && (
                      <BodyMedium>混雑度: {toDropInCrowdingLevel(item.crowdingRatio)}</BodyMedium>
                    )}
                    <CardRow>
                      <BodyMedium>利用可能時間: </BodyMedium>
                      <div>
                        {convertToAvailableTimes(item.availableTimes, base.timezone).map((time, index) => (
                          <BodyMedium key={index}>
                            {time.weekday} {time.time}
                          </BodyMedium>
                        ))}
                      </div>
                    </CardRow>
                  </CardBody>
                </SpaceCard>
              );
            })}
          {!isLoading && !hasLength(filteredDropInItems) && (
            <NoResourceMessage>利用可能なスペースが見つかりませんでした。</NoResourceMessage>
          )}
        </DropInItems>
      </Content>
      {selectedDropItem && (
        <Content data-testid='select-pack' ref={packRef}>
          <TextWrapper>
            <Title>利用内容の選択（2/2）</Title>
            <BodyLarge>利用内容を選択してください。</BodyLarge>
          </TextWrapper>
          <DropInItems>
            {selectedDropItem.plans.map((plan, idx) => (
              <SpaceCard
                key={`pack-card-${idx}`}
                id={plan.spacePlanId}
                selectedIds={selectedSpacePlanId ? [selectedSpacePlanId] : []}
                handleClicked={setSelectedSpacePlanId}
              >
                <CardBody>
                  <Title>{plan.planName}</Title>
                  <LabelLarge>料金: ￥{plan.totalPrice.toLocaleString()}</LabelLarge>
                  <CardRow>
                    <BodyMedium>利用可能時間: </BodyMedium>
                    <div>
                      {convertToAvailableTimes(selectedDropItem.availableTimes, base.timezone).map((time, index) => (
                        <BodyMedium key={index}>
                          {time.weekday} {time.time}
                        </BodyMedium>
                      ))}
                    </div>
                  </CardRow>
                </CardBody>
              </SpaceCard>
            ))}
          </DropInItems>
        </Content>
      )}
      <Content data-testid='notes' ref={noteRef}>
        <Title>ご利用方法・注意事項</Title>
        <BodyLarge>{base.spaceNotes}</BodyLarge>
      </Content>
      <Content data-testid='payments'>
        <Title>お支払方法</Title>
        <BodyLarge>{base.spacePayments}</BodyLarge>
      </Content>
      <StyledButton
        disabled={!selectedSpacePlanId}
        width={272}
        type='primary'
        size='large'
        label='確認画面へ'
        onClick={openDropInConfirmScreen}
      />
    </Container>
  );
});

DropInEntryScreen.displayName = 'DropInEntryScreen';
export default DropInEntryScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
`;

const Content = styled.div`
  background: ${themeV3.mixins.v3.color.object.white};
  border-radius: 12px;
  padding: ${themeV3.mixins.v3.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;

  ${media.lessThan('small')`
    padding: ${themeV3.mixins.v3.spacing * 2}px;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const DropInItems = styled.div`
  min-height: 160px;
  display: flex;
  flex-wrap: wrap;
  gap: ${themeV3.mixins.v3.spacing * 3}px;

  ${media.lessThan('small')`
    flex-direction: column;
    gap: ${themeV3.mixins.v3.spacing * 2}px;
  `}
`;

const Title = styled.div`
  ${themeV3.mixins.v3.typography.title.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const BodyLarge = styled.div`
  ${themeV3.mixins.v3.typography.body.large};
  color: ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const BodyMedium = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const LabelLarge = styled.div`
  ${themeV3.mixins.v3.typography.label.large};
  color: ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const CardRow = styled.div`
  width: 100%;
  display: flex;
`;

const StyledButton = styled(ButtonV2)`
  margin-inline: auto;
  ${media.lessThan('small')`
    width: 100% !important;
  `}
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoResourceMessage = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  margin: auto;
`;
