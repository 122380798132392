import {
  ButtonV2,
  Component,
  customMedia,
  DialogV2,
  PageHeaderV2,
  TabsV2,
  themeV2,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCategory,
  AuthorityDefCodeEnum,
  BaseDto,
  ContractV2,
  ContractVersion,
  FETCH_CONTRACT_V2_BY_USER,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  Gender,
  User
} from '@atomica.co/irori';
import { City, Code, URL } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, Prefecture, builder, hasLength, uuid } from '@atomica.co/utils';
import { Divider } from '@material-ui/core';
import { DeleteOutline, MeetingRoomOutlined } from '@material-ui/icons';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ContractUsageV2Dto, FetchContractUsageParams, SaveUserBody } from '../../../__generated/model';
import { getBsContract } from '../../../__generated/user/bs-contract/bs-contract';
import { getBsUser } from '../../../__generated/user/bs-user/bs-user';
import { PRIVACY_POLICY_FOR_KNOT_PLACE, TERMS_OF_USE_FOR_KNOT_PLACE } from '../../../constants/account-const';
import { MOBILE_MIN_WIDTH,MOBILE_MAX_WIDTH } from '../../../constants/common-const';
import { ImageCategory } from '../../../enums/common-enum';
import { storage } from '../../../firebase';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCity from '../../../redux/hooks/useCity';
import usePath from '../../../redux/hooks/usePath';
import { screenContext } from '../../../redux/hooks/useScreenContext';
import useUser from '../../../redux/hooks/useUser';
import useUserDiv from '../../../redux/hooks/useUserDiv';
import useUserInflowSource from '../../../redux/hooks/useUserInflowSource';
import useUserStudentDiv from '../../../redux/hooks/useUserStudentDiv';
import CommonRequest from '../../../requests/common-request';
import { Path } from '../../../router/Routes';
import { ImageService } from '../../../services/image-service';
import { openAccountDeletion } from '../../../utils/account-util';
import { findSpecificCities } from '../../../utils/prefecture-city-utils';
import { getBasePICV2 } from '../../../utils/user-util';
import AccountEnterExitHistoryCard from './usage-information/AccountEnterExitHistoryCard';
import AccountUsageSituationCard from './usage-information/AccountUsageSituationCard';
import AccountProfileCardEdit from './user-information-v2/AccountProfileCardEdit';
import AccountProfileCardView from './user-information-v2/AccountProfileCardView';

interface P {
  base: BaseDto;
  user: User;
}

enum TAB_SELECTION {
  REGISTER_INFO = 0,
  USAGE_INFO = 1,
  ENTER_LEAVE_HISTORY = 2
}

const AccountProfileV2: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const { userDivs, loadUserDivs } = useUserDiv();
  const { userStudentDivs, loadUserStudentDivs } = useUserStudentDiv();
  const { userInflowSources, loadUserInflowSources } = useUserInflowSource();
  const { prefectureWithCities, loadPrefectureWithCities } = useCity();

  const isMobile = useMobile();

  const unmountRef = useUnmountRef();
  const [initialized, setInitilized] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isEditMode, setIsEditMode] = useSafeState<boolean>(unmountRef, false);
  const [tabSelection, setTabSelection] = useSafeState<TAB_SELECTION>(unmountRef, TAB_SELECTION.REGISTER_INFO);
  const [editableUser, setEditableUser] = useSafeState(unmountRef, user);
  const [contract, setContract] = useSafeState<ContractV2>(unmountRef);
  const [contractUsage, setContractUsage] = useSafeState<ContractUsageV2Dto>(unmountRef);
  const [facePhotoDownloadURL, setFacePhotoDownloadURL] = useSafeState<URL>(unmountRef, EMPTY);
  const [userPhotoFileToSave, setUserPhotoFileToSave] = useSafeState<File>(unmountRef);
  const [facePhotoFileToSave, setFacePhotoFileToSave] = useSafeState<File>(unmountRef);
  const [saveButtonDisabled, setSaveButtonDisabled] = useSafeState<boolean>(unmountRef, false);
  const { setIsDrawerOpen, setUserAndPropagate } = useContext(screenContext);
  const { fetchUser } = useUser({ noInitialize: true });

  const { openSnackbar } = useSnackbarV2();

  const cities = useMemo<City[]>(
    () => findSpecificCities(prefectureWithCities, editableUser.prefecture),
    [editableUser.prefecture, prefectureWithCities]
  );

  const getContract = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const { contract } = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    setContract(contract);
  }, [base.baseId, setContract, user.userId]);

  const getContractUsage = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractUsageParams>().version(ContractVersion.V2).build();
    const { data: response } = await getBsContract().fetchContractUsage(base.baseId, user.userId, request);
    if (response.usage) {
      setContractUsage(response.usage);
    }
  }, [base, setContractUsage, user]);

  const openSignOut = useSafeCallback((): void => {
    openBasePath(Path.SIGN_OUT);
  }, [openBasePath]);

  const openTermOfUse = useSafeCallback((): void => {
    window.open(TERMS_OF_USE_FOR_KNOT_PLACE, '_blank');
  }, []);

  const openPrivacyPolicy = useSafeCallback((): void => {
    window.open(PRIVACY_POLICY_FOR_KNOT_PLACE, '_blank');
  }, []);

  const handleProfileEditButtonClicked = useSafeCallback((): void => {
    setIsDrawerOpen(false);
    setIsEditMode(true);
  }, [setIsEditMode, setIsDrawerOpen]);
  const handleTabChanged = useSafeCallback(
    (selectedIndex): void => {
      setTabSelection(selectedIndex);
      setIsDrawerOpen(true);
      setIsEditMode(false);
    },
    [setTabSelection, setIsDrawerOpen, setIsEditMode]
  );
  const handlerCancelButtonClicked = useSafeCallback((): void => {
    setIsEditMode(false);
    setIsDrawerOpen(true);
    setEditableUser(user);
  }, [setIsEditMode, setEditableUser, user, setIsDrawerOpen]);

  const getFacePhotoDownloadURL = useSafeCallback(async (): Promise<void> => {
    if (!user.faceRecognitionPhotoURL) return;

    const isUrl = user.faceRecognitionPhotoURL.startsWith('http');
    if (isUrl) {
      setFacePhotoDownloadURL(user.faceRecognitionPhotoURL);
      return;
    }

    const ref = storage.ref(user.faceRecognitionPhotoURL);
    const downloadURL = await ref.getDownloadURL();
    setFacePhotoDownloadURL(downloadURL);
  }, [user.faceRecognitionPhotoURL, setFacePhotoDownloadURL]);

  const handlerSaveButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (saving) return;
    setSaving(true);

    const [photoURL, faceRecognitionPhotoURL] = await Promise.all([
      userPhotoFileToSave
        ? ImageService.uploadImageToFirebase(userPhotoFileToSave, ImageCategory.USER_PROFILE, uuid())
        : EMPTY,
      facePhotoFileToSave
        ? ImageService.uploadImageToFirebase(facePhotoFileToSave, ImageCategory.USER_FACE, uuid())
        : EMPTY
    ]);

    const userRequest = builder<SaveUserBody>()
      .familyName(editableUser.familyName ?? EMPTY)
      .firstName(editableUser.firstName ?? EMPTY)
      .email(user.email) // FIXME: メールアドレスはここで変更すると不整合になりそう。対応要検討
      .gender(editableUser.gender ?? Gender.OTHER)
      .dateOfBirthV2(editableUser.dateOfBirthV2 ?? EMPTY)
      .prefecture(editableUser.prefecture ?? Prefecture.TOKYO)
      .city(editableUser.city ?? EMPTY)
      .userDiv(editableUser.userDiv)
      .userDivOther(editableUser.userDivOther ?? EMPTY)
      .campanyName(editableUser.companyName ?? EMPTY)
      .userStudentDiv(editableUser.userStudentDiv)
      .userStudentDivOther(editableUser.userStudentDivOther ?? EMPTY)
      .schoolName(editableUser.schoolName ?? EMPTY)
      .schoolDepartmentName(editableUser.schoolDepartmentName ?? EMPTY)
      .phone(editableUser.phone ?? EMPTY)
      .photoURL((photoURL || editableUser.photoURL) ?? EMPTY)
      .faceRecognitionPhotoURL((faceRecognitionPhotoURL || editableUser.faceRecognitionPhotoURL) ?? EMPTY)
      .faceFeatureStr(editableUser.faceFeatureStr ?? EMPTY)
      .userInflowSource(editableUser.userInflowSource)
      .userInflowSourceOther(editableUser.userInflowSourceOther ?? EMPTY)
      .userUsagePurpose(editableUser.userUsagePurpose)
      .userUsagePurposeOther(editableUser.userUsagePurposeOther ?? EMPTY)
      .userFamilyMembers(editableUser.userFamilyMemberMappings?.map(mapping => mapping.userFamilyMember!) ?? [])
      .userFamilyMemberOther(
        editableUser.userFamilyMemberMappings?.find(mapping => mapping.userFamilyMember?.userFamilyMemberDef?.hasOther)
          ?.other ?? EMPTY
      )
      .dateOfAgreement(editableUser.dateOfAgreement ?? new Date())
      .providers([])
      .build();

    const { data: response } = await getBsUser().saveUser(userRequest);

    if (!response.userId) {
      setSaving(false);
      openSnackbar('プロフィールの保存に失敗しました 時間をおいてもう一度お試しください', 'error', 4000);
      return;
    }

    const updatedUser = await fetchUser();
    if (updatedUser) setUserAndPropagate(updatedUser);
    openSnackbar('プロフィールの保存に成功しました', 'success', 4000);
    setSaving(false);
    setIsEditMode(false);
  }, [
    editableUser,
    facePhotoFileToSave,
    openSnackbar,
    saving,
    setIsEditMode,
    setSaving,
    setUserAndPropagate,
    user.email,
    userPhotoFileToSave,
    fetchUser
  ]);

  const authorityDefCode = useMemo<Code | undefined>(() => {
    const pics = getBasePICV2(user, base, AuthorityDefCategory.USER_AUTHORITY);
    return hasLength(pics) ? pics[FIRST_INDEX].authority?.authorityDef?.authorityDefCode : undefined;
  }, [base, user]);

  const useFaceAccess = useMemo<boolean>(() => {
    return authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorityDefCode]);

  const initialLoad = useSafeCallback(async (): Promise<void> => {
    if (initialized) return;
    await Promise.all([
      getContract(),
      getContractUsage(),
      getFacePhotoDownloadURL(),
      loadUserDivs(base.baseCode),
      loadUserStudentDivs(base.baseCode),
      loadUserInflowSources(base.baseCode),
      loadPrefectureWithCities()
    ]);
    setInitilized(true);
  }, [
    base.baseCode,
    getContract,
    getContractUsage,
    getFacePhotoDownloadURL,
    initialized,
    loadPrefectureWithCities,
    loadUserDivs,
    loadUserInflowSources,
    loadUserStudentDivs,
    setInitilized
  ]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  useEffect(() => {
    if (!initialized) return;
    getFacePhotoDownloadURL();
  }, [getFacePhotoDownloadURL, initialized, user.faceRecognitionPhotoURL]);

  return (
    <Component style={{ width: '100%', height: '100%' }} className='account-profile' title={'マイページ'}>
      <Container>
        <Content isMobile={isMobile}>
          <PageHeaderV2
            title='マイページ'
            titleSize={isMobile ? 'small' : 'default'}
            rightComponent={
              !isEditMode &&
              tabSelection === TAB_SELECTION.REGISTER_INFO && (
                <ButtonV2 type='primary' onClick={handleProfileEditButtonClicked} label='プロフィールを編集' />
              )
            }
          />

          <TabsV2
            tabComponents={[
              {
                label: 'ご登録情報',
                component: (
                  <ProfileViewFrame isEditMode={isEditMode}>
                    <TitleWrapper data-testid='account-profile-title'>
                      <Title>プロフィール</Title>
                      <StyledDivider variant='fullWidth' flexItem />
                    </TitleWrapper>

                    {isEditMode && (
                      <AccountProfileCardEdit
                        base={base}
                        user={editableUser}
                        setUser={setEditableUser}
                        userDivs={userDivs}
                        userStudentDivs={userStudentDivs}
                        userInflowSources={userInflowSources}
                        cities={cities}
                        // userUsagePurposes={userUsagePurposes}
                        // userFamilyMembers={userFamilyMembers}
                        facePhotoDownloadURL={facePhotoDownloadURL}
                        useFaceAccess={useFaceAccess}
                        setUserPhotoFileToSave={setUserPhotoFileToSave}
                        setFacePhotoFileToSave={setFacePhotoFileToSave}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    )}

                    {!isEditMode && (
                      <AccountProfileCardView
                        base={base}
                        user={user}
                        facePhotoDownloadURL={facePhotoDownloadURL}
                        useFaceAccess={useFaceAccess}
                      />
                    )}
                  </ProfileViewFrame>
                )
              },
              {
                label: 'ご利用状況',
                component: (
                  <AccountUsageSituationCard
                    base={base}
                    user={user}
                    contractUsage={contractUsage}
                    contract={contract}
                  />
                )
              },
              {
                label: 'エントランス入退履歴',
                component: <AccountEnterExitHistoryCard base={base} user={user} />
              }
            ]}
            selectedTabIdx={tabSelection}
            onChange={handleTabChanged}
          />
          {!!isEditMode && tabSelection === TAB_SELECTION.REGISTER_INFO ? (
            <FooterEdit>
              <ButtonV2
                size='medium'
                type='primary'
                label='プロフィールを保存'
                disabled={saving || saveButtonDisabled}
                onClick={handlerSaveButtonClicked}
              />
              <ButtonV2 type='default' label='キャンセル' onClick={handlerCancelButtonClicked} />
            </FooterEdit>
          ) : (
            <Footer>
              <ButtonWrapper>
                <ButtonV2
                  startIcon={<MeetingRoomOutlined />}
                  type='default'
                  label='ログアウト'
                  onClick={() => setIsModalOpen(true)}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <StyledButtonV2
                  startIcon={<DeleteOutline />}
                  type='default'
                  label='アカウント削除'
                  onClick={openAccountDeletion}
                />
              </ButtonWrapper>
              <StyledDivider variant='fullWidth' flexItem />
              <TermOfService>
                <ButtonV2 type='default' label='利用規約' onClick={openTermOfUse} />
                <ButtonV2 type='default' label='プライバシーポリシー' onClick={openPrivacyPolicy} />
              </TermOfService>
            </Footer>
          )}
        </Content>

        <DialogV2
          width={280}
          isOpen={isModalOpen}
          headerLabel='ログアウトしますか？'
          buttonsOnTheRight={[
            <ButtonV2 key='cancel' label='いいえ' onClick={() => setIsModalOpen(false)} />,
            <ButtonV2 type='primary' key='sign-out' label='はい' onClick={openSignOut} />
          ]}
          onClose={() => setIsModalOpen(false)}
        />
      </Container>
    </Component>
  );
});

AccountProfileV2.displayName = 'AccountProfileV2';
export default AccountProfileV2;

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  ${customMedia.greaterThan('small')`
    min-width: 640px;
  `}
  display: flex;
  flex-direction: column;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  gap: ${({ isMobile }) => (isMobile ? themeV2.mixins.v2.spacing * 3 : themeV2.mixins.v2.spacing * 2)}px;
  flex: ${({ isMobile }) => {
    if (!isMobile) return '1 0 0';
  }};
  justify-content: ${({ isMobile }) => {
    if (isMobile) return 'center';
  }};
  padding: ${({ isMobile }) =>
    isMobile
      ? `${themeV2.mixins.v2.spacing * 2}px`
      : `${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 2}px`};
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing}px;
  flex-shrink: 0;
`;

const Title = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TermOfService = styled(ButtonWrapper)`
  padding-bottom: ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 4}px;
  justify-content: center;
  text-align: center;
`;

const StyledDivider = styled(Divider)`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  width: auto;
`;

const ProfileViewFrame = styled.div<{ isEditMode: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 4}px;
  border-radius: 12px;
  margin-bottom: ${({ isEditMode }) => (isEditMode ? 80 : 0)}px;
`;

const StyledButtonV2 = styled(ButtonV2)`
  color: ${themeV2.mixins.v2.color.font.pink} !important;
`;

const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  display: flex;
`;

const FooterEdit = styled(Footer)`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: ${themeV2.mixins.v2.spacing * 2}px 0px;
  background: ${themeV2.mixins.v2.color.background.white};
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
