import {
  FormGroupSelect,
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelV2,
  MasterSearchOption,
  MasterSearchV2Enhanced,
  RadioBoxV2,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  ContractorType,
  Customer,
  CustomerType,
  InflowSource,
  SEARCH_CUSTOMER_FOR_ADMIN,
  SearchCustomerForAdminRequest,
  SearchCustomerForAdminResponse
} from '@atomica.co/irori';
import { Address, City, Email, Name, NoStr, Phone, Post, Remarks, Word } from '@atomica.co/types';
import { EMPTY, PREFECTURE_NAMES, Prefecture, builder } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { MASTER_SEARCH_LIMIT, MAX_ENTITIY_NAME_LENGTH } from '../../../constants/contract-v2-const';
import { toMasterSearchOptionFromCustomer } from '../../../converters/contract-v2-converter';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { CONTRACTOR_TYPE_LABELS, CONTRACT_INFLOW_SOURCE_LABELS } from '../../../texts/contract-v2-text';

export interface BasicInputValues {
  contractNo: NoStr;
  startDate: Date | null;
  endDate: Date | null;
  contractorType: ContractorType;
  entityName: Name;
  familyName: Name;
  firstName: Name;
  familyNameKana: Name;
  firstNameKana: Name;
  representativeFamilyName: Name;
  representativeFirstName: Name;
  representativeFamilyNameKana: Name;
  representativeFirstNameKana: Name;
  contractorFamilyName: Name;
  contractorFirstName: Name;
  contractorFamilyNameKana: Name;
  contractorFirstNameKana: Name;
  contractorPost: Post;
  contractorAddress: Address;
  contractorPrefecture: Prefecture | undefined;
  contractorCity: City;
  email: Email;
  phone: Phone;
  remarks: Remarks;
  inFlowSource: InflowSource;
}

export interface BasicInputOnChanges {
  setContractNo: (contractNo: NoStr) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date | null) => void;
  setContractorType: (contractorType: ContractorType) => void;
  setEntityName: (entityName: Name) => void;
  setFamilyName: (familyName: Name) => void;
  setFirstName: (firstName: Name) => void;
  setFamilyNameKana: (familyNameKana: Name) => void;
  setFirstNameKana: (firstNameKana: Name) => void;
  setRepresentativeFamilyName: (representativeFamilyName: Name) => void;
  setRepresentativeFirstName: (representativeFirstName: Name) => void;
  setRepresentativeFamilyNameKana: (representativeFamilyNameKana: Name) => void;
  setRepresentativeFirstNameKana: (representativeFirstNameKana: Name) => void;
  setContractorFamilyName: (contractorFamilyName: Name) => void;
  setContractorFirstName: (contractorFirstName: Name) => void;
  setContractorFamilyNameKana: (contractorFamilyNameKana: Name) => void;
  setContractorFirstNameKana: (contractorFirstNameKana: Name) => void;
  setContractorPost: (contractorPost: Post) => void;
  setContractorAddress: (contractorAddress: Address) => void;
  setContractorPrefecture: (contractorAddress: Prefecture | undefined) => void;
  setContractorCity: (contractorAddress: City) => void;
  setEmail: (email: Email) => void;
  setPhone: (phone: Phone) => void;
  setRemarks: (remarks: Remarks) => void;
  setInFlowSource: (inFlowSource: InflowSource) => void;
  setCustomerId: (customerId: string) => void;
}

interface P {
  base: BaseDto;
  isEdit: boolean;
  values: BasicInputValues;
  onChanges: BasicInputOnChanges;
}

const BasicInput: React.FC<P> = React.memo(props => {
  const { base, isEdit, values, onChanges } = props;
  const {
    contractNo,
    startDate,
    endDate,
    contractorType,
    entityName,
    familyName,
    firstName,
    familyNameKana,
    firstNameKana,
    representativeFamilyName,
    representativeFirstName,
    representativeFamilyNameKana,
    representativeFirstNameKana,
    contractorFamilyName,
    contractorFirstName,
    contractorFamilyNameKana,
    contractorFirstNameKana,
    contractorPost,
    contractorAddress,
    contractorCity,
    contractorPrefecture,
    email,
    phone,
    remarks,
    inFlowSource
  } = values;

  const {
    setContractNo,
    setStartDate,
    setEndDate,
    setContractorType,
    setEntityName,
    setFamilyName,
    setFirstName,
    setFamilyNameKana,
    setFirstNameKana,
    setRepresentativeFamilyName,
    setRepresentativeFirstName,
    setRepresentativeFamilyNameKana,
    setRepresentativeFirstNameKana,
    setContractorFamilyName,
    setContractorFirstName,
    setContractorFamilyNameKana,
    setContractorFirstNameKana,
    setContractorPost,
    setContractorAddress,
    setContractorPrefecture,
    setContractorCity,
    setEmail,
    setPhone,
    setRemarks,
    setInFlowSource,
    setCustomerId
  } = onChanges;
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [customers, setCustomers] = useSafeState<Customer[]>(unmountRef, []);

  const handleClearEntity = useSafeCallback((): void => {
    if (!setEntityName) return;
    setEntityName(EMPTY);
  }, [setEntityName]);

  const handleCreateCustomer = useSafeCallback((): void => {
    if (!setEntityName || !setCustomerId) return;
    setCustomerId(EMPTY);
    setEntityName(entityName);
  }, [entityName, setCustomerId, setEntityName]);

  const handleSelectEntity = useSafeCallback(
    (option: MasterSearchOption): void => {
      const findEntity = customers.find(customer => customer.customerId === option.value);
      if (findEntity) {
        setEntityName(findEntity.customerName);
        setCustomerId(findEntity.customerId);
      }
    },
    [setEntityName, customers]
  );

  const handleSearchEntities = useSafeCallback(
    async (word: Word): Promise<void> => {
      setEntityName(word);
      if (!word) return;
      const request = builder<SearchCustomerForAdminRequest>()
        .baseId(base.baseId)
        .customerType(CustomerType.CORPORATE)
        .customerName(word)
        .build();
      const response = await commonRequest<SearchCustomerForAdminRequest, SearchCustomerForAdminResponse>(
        SEARCH_CUSTOMER_FOR_ADMIN,
        request
      );
      setCustomers(response.customers);
    },
    [base.baseId, commonRequest, setCustomers, setEntityName]
  );

  return (
    <BasicContent>
      <ContentTitle>基本情報</ContentTitle>
      <Rows>
        {contractNo && (
          <Row>
            <Cell colspan={4}>
              <InputWithLabelV2 readonly text='契約番号' value={contractNo} onChange={setContractNo} />
            </Cell>
            <Cell colspan={4} />
          </Row>
        )}
        <Row>
          <Cell colspan={4}>
            <InputDateWithLabelV2 text='契約開始日' value={startDate} onChange={setStartDate} required />
          </Cell>
          <Cell colspan={4}>
            <InputDateWithLabelV2 text='契約終了日' value={endDate} onChange={setEndDate} />
          </Cell>
        </Row>
        <CustomRow>
          <LabelV2 required text='契約者' />
          <RadioWrapper>
            <RadioBoxV2
              options={[ContractorType.ENTITY, ContractorType.PERSON]}
              disabledOptions={isEdit ? [ContractorType.ENTITY, ContractorType.PERSON] : []}
              labels={CONTRACTOR_TYPE_LABELS}
              onChange={setContractorType}
              value={contractorType}
              gap={40}
            />
          </RadioWrapper>
          {contractorType === ContractorType.ENTITY && (
            <Cell>
              <MasterSearchV2Enhanced
                placeholder='法人名で検索'
                labelForNoData='選択可能な法人名がありません'
                options={toMasterSearchOptionFromCustomer(customers)}
                actions={
                  entityName && !customers.some(customer => customer.customerName === entityName)
                    ? [{ label: `+ 「${entityName}」を新規作成`, onClick: handleCreateCustomer }]
                    : undefined
                }
                handleOptionCleared={handleClearEntity}
                handleOptionSelected={handleSelectEntity}
                onChange={handleSearchEntities}
                selectedWord={entityName}
                limit={MASTER_SEARCH_LIMIT}
                maxLength={MAX_ENTITIY_NAME_LENGTH}
              />
            </Cell>
          )}

          {contractorType === ContractorType.PERSON && (
            <CustomRow>
              <Row>
                <Cell colspan={4}>
                  <LabelV2 required text='姓' />
                  <TextFieldV2 value={familyName} onChange={setFamilyName} maxLength={125} />
                </Cell>
                <Cell colspan={4}>
                  <LabelV2 required text='名' />
                  <TextFieldV2 value={firstName} onChange={setFirstName} maxLength={125} />
                </Cell>
              </Row>
              <Row>
                <Cell colspan={4}>
                  <LabelV2 required text='姓（カナ）' />
                  <TextFieldV2 value={familyNameKana} onChange={setFamilyNameKana} maxLength={125} />
                </Cell>
                <Cell colspan={4}>
                  <LabelV2 required text='名（カナ）' />
                  <TextFieldV2 value={firstNameKana} onChange={setFirstNameKana} maxLength={125} />
                </Cell>
              </Row>
            </CustomRow>
          )}
        </CustomRow>

        {contractorType === ContractorType.ENTITY && (
          <CustomRow>
            <LabelV2 text='代表者氏名' remarks='法人の代表者の氏名を記載します' />
            <Row>
              <Cell colspan={4}>
                <LabelV2 required text='姓' />
                <TextFieldV2 value={representativeFamilyName} onChange={setRepresentativeFamilyName} maxLength={125} />
              </Cell>
              <Cell colspan={4}>
                <LabelV2 required text='名' />
                <TextFieldV2 value={representativeFirstName} onChange={setRepresentativeFirstName} maxLength={125} />
              </Cell>
            </Row>
            <Row>
              <Cell colspan={4}>
                <LabelV2 required text='姓（カナ）' />
                <TextFieldV2
                  value={representativeFamilyNameKana}
                  onChange={setRepresentativeFamilyNameKana}
                  maxLength={125}
                />
              </Cell>
              <Cell colspan={4}>
                <LabelV2 required text='名（カナ）' />
                <TextFieldV2
                  value={representativeFirstNameKana}
                  onChange={setRepresentativeFirstNameKana}
                  maxLength={125}
                />
              </Cell>
            </Row>
          </CustomRow>
        )}
        <CustomRow gap={16}>
          <LabelV2 text='契約者住所' />
          <Row>
            <Cell colspan={2}>
              <InputWithLabelV2
                required
                text='郵便番号'
                value={contractorPost}
                onChange={setContractorPost}
                maxLength={8}
              />
            </Cell>
            <Cell colspan={6} />
          </Row>
          <Row>
            <Cell colspan={2}>
              <FormGroupSelect
                required
                placeholder='都道府県を選択'
                title='都道府県'
                options={Object.values(Prefecture)}
                labels={PREFECTURE_NAMES}
                value={contractorPrefecture || false}
                onChange={setContractorPrefecture}
              />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <InputWithLabelV2
                required
                text='市区町村'
                value={contractorCity}
                onChange={setContractorCity}
                maxLength={255}
              />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <InputWithLabelV2
                required
                text='番地・建物名等'
                value={contractorAddress}
                onChange={setContractorAddress}
                maxLength={255}
              />
            </Cell>
          </Row>
        </CustomRow>
        {contractorType === ContractorType.ENTITY && (
          <CustomRow>
            <LabelV2 text='契約先担当者' remarks='契約・請求に関する連絡をする担当者の氏名を入力します。' />
            <Row>
              <Cell colspan={4}>
                <LabelV2 required text='姓' />
                <TextFieldV2 value={contractorFamilyName} onChange={setContractorFamilyName} maxLength={125} />
              </Cell>
              <Cell colspan={4}>
                <LabelV2 required text='名' />
                <TextFieldV2 value={contractorFirstName} onChange={setContractorFirstName} maxLength={125} />
              </Cell>
            </Row>
            <Row>
              <Cell colspan={4}>
                <LabelV2 required text='姓（カナ）' />
                <TextFieldV2 value={contractorFamilyNameKana} onChange={setContractorFamilyNameKana} maxLength={125} />
              </Cell>
              <Cell colspan={4}>
                <LabelV2 required text='名（カナ）' />
                <TextFieldV2 value={contractorFirstNameKana} onChange={setContractorFirstNameKana} maxLength={125} />
              </Cell>
            </Row>
          </CustomRow>
        )}
        <Row>
          <Cell colspan={4}>
            {/* HACK: 2025/4 一時メールアドレスの変更を禁止 - Stripeの支払い先情報に関連 */}
            <InputWithLabelV2
              text='契約先担当者 メールアドレス'
              required
              value={email}
              onChange={setEmail}
              maxLength={255}
              readonly={isEdit}
            />
          </Cell>
          <Cell colspan={4}>
            <InputWithLabelV2 required text='契約先担当者 電話番号' value={phone} onChange={setPhone} maxLength={255} />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <InputWithLabelV2 text='備考' multiline value={remarks} onChange={setRemarks} />
          </Cell>
        </Row>
        <CustomRow>
          <LabelV2 required text='流入経路' />
          <RadioWrapper>
            <RadioBoxV2
              options={[InflowSource.ORGANIC, InflowSource.DIRECT, InflowSource.SHOT]}
              labels={CONTRACT_INFLOW_SOURCE_LABELS}
              onChange={setInFlowSource}
              value={inFlowSource}
              gap={40}
            />
          </RadioWrapper>
        </CustomRow>
      </Rows>
    </BasicContent>
  );
});

BasicInput.displayName = 'BasicInput';
export default BasicInput;

const Content = styled.div`
  background-color: #faf9f7;
  border-radius: 6px;
  padding: ${themeV2.mixins.v2.spacing * 3}px 0 ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
`;
const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  align-items: end;
`;
const Cell = styled.div<{ colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 }>`
  width: ${({ colspan = 8 }) => `calc(calc(calc(100% / 8) * ${colspan}) - ${themeV2.mixins.v2.spacing * 3}px )`};
`;
const BasicContent = styled(Content)``;

const ContentTitle = styled(Typography)`
  grid-area: title2;
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;

const CustomRow = styled.div<{ gap?: number }>`
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap = themeV2.mixins.v2.spacing / 2 }) => gap}px;
`;

const RadioWrapper = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing * 2}px;
`;
