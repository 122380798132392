import {
  BackButtonV2,
  Button,
  ButtonV2,
  Component,
  DialogV2,
  PageHeaderV2,
  Skeleton,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCategory,
  AuthorityDefCodeEnum,
  BaseDto,
  BaseFunctionToggleCode,
  ContractV2,
  ContractVersion,
  Direction,
  FETCH_CONTRACT_V2_BY_USER,
  FetchAccessesRequest,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  UPDATE_USER_QFACE,
  UpdateUserQFaceRequest,
  UpdateUserQFaceResponse,
  User,
  UserPoint,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Code, Count, Label, Message, Name, Offset, Point, URL } from '@atomica.co/types';
import {
  EMPTY,
  FIRST_INDEX,
  Language,
  ZERO,
  builder,
  hasLength,
  isGreaterThanZero,
  toDateTimeStr
} from '@atomica.co/utils';
import { ListItemText, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InstagramIcon from '@material-ui/icons/Instagram';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { AccessV2Dto, ContractUsageV2Dto, FetchContractUsageParams } from '../../../__generated/model';
import { getBsContract } from '../../../__generated/user/bs-contract/bs-contract';
import { getBsSpace } from '../../../__generated/user/bs-space/bs-space';
import ListV2, { ListRow } from '../../../components/list/List';
import { PRIVACY_POLICY_FOR_KNOT_PLACE, TERMS_OF_USE_FOR_KNOT_PLACE } from '../../../constants/account-const';
import { toContractPlanLabel } from '../../../constants/base-const';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { ERROR, SUCCESS } from '../../../constants/snackbar-const';
import { storage } from '../../../firebase';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { screenContext } from '../../../redux/hooks/useScreenContext';
import CommonRequest from '../../../requests/common-request';
import { Path } from '../../../router/Routes';
import { ImageService } from '../../../services/image-service';
import { QFACE_SET_ERROR, QFACE_SET_SAVED } from '../../../texts/snackbar-text';
import { openAccountDeletion } from '../../../utils/account-util';
import { getContractVersion } from '../../../utils/contract-v2-util';
import { toTimeStr } from '../../../utils/date-util';
import { getBasePICV2 } from '../../../utils/user-util';
import mojaco from './../../../assets/mojaco/mojaco_break.png';

const LIMIT = 5;

interface P {
  base: BaseDto;
  user: User;
}

const AccountProfile: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isQFaceEditable, setIsQFaceEditable] = useSafeState<boolean>(unmountRef, false);
  const [faceRecognitionPhotoURL, setFaceRecognitionPhotoURL] = useSafeState<URL | undefined>(
    unmountRef,
    user.faceRecognitionPhotoURL
  );
  const [facePhotoDownloadURL, setFacePhotoDownloadURL] = useSafeState<URL>(unmountRef);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);
  const [usageSummary, setUsageSummary] = useSafeState<ContractUsageV2Dto | undefined>(unmountRef);
  const [pastAccesses, setPastAccesses] = useSafeState<AccessV2Dto[]>(unmountRef, []);
  const [version, setVersion] = useSafeState<ContractVersion>(unmountRef);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const { setUserAndPropagate } = useContext(screenContext);

  const totalUserPoint = useMemo<Point>(() => {
    if (!user || !hasLength(user.userPoints)) return 0;
    return user.userPoints!.reduce((total: Point, userPoint: UserPoint): Point => total + userPoint.point, 0);
  }, [user]);

  const authorityDefCode = useMemo<Code | undefined>(() => {
    const pics = getBasePICV2(user, base, AuthorityDefCategory.USER_AUTHORITY);
    return hasLength(pics) ? pics[FIRST_INDEX].authority?.authorityDef?.authorityDefCode : undefined;
  }, [base, user]);

  const useFaceAccess = useMemo<boolean>(() => {
    return authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorityDefCode]);

  const contractPlanLabel = useMemo<Label>(() => {
    switch (version) {
      case ContractVersion.V1:
        return toContractPlanLabel(base.baseCode, user.contract?.contractPlan);
      case ContractVersion.V2:
        return contract?.contractPlan?.contractPlanName || EMPTY;
      default:
        return EMPTY;
    }
  }, [version, base, user, contract]);

  const getFacePhotoDownloadURL = useSafeCallback(async (): Promise<void> => {
    if (!faceRecognitionPhotoURL) return;
    const ref = storage.ref(faceRecognitionPhotoURL);
    const downloadURL = await ref.getDownloadURL();
    setFacePhotoDownloadURL(downloadURL);
  }, [faceRecognitionPhotoURL, setFacePhotoDownloadURL]);

  const getContract = useSafeCallback(async (): Promise<ContractV2 | undefined> => {
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const { contract } = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    return contract;
  }, [base, user]);

  const fetchContractUsageAndAccess = useSafeCallback(async (): Promise<void> => {
    const contract = await getContract();
    const version = getContractVersion(user, contract);

    if (!version) {
      setLoaded(true);
      return;
    }

    setContract(contract);
    setVersion(version);

    const request = builder<FetchContractUsageParams>().version(ContractVersion.V2).build();

    const accessRequest = builder<FetchAccessesRequest>()
      .baseCode(base.baseCode)
      .limit(LIMIT)
      .offset(offset * LIMIT)
      .version(version)
      .build();
    const [usageResponse, accessResponse] = await Promise.all([
      getBsContract().fetchContractUsage(base.baseId, user.userId, request),
      getBsSpace().fetchAccesses(base.baseId, user.userId, accessRequest)
    ]);

    setUsageSummary(usageResponse.data.usage);
    setPastAccesses(accessResponse.data.accesses);
    setTotalCount(accessResponse.data.totalCount);
    setLoaded(true);
  }, [
    base.baseCode,
    base.baseId,
    getContract,
    offset,
    setContract,
    setLoaded,
    setPastAccesses,
    setTotalCount,
    setUsageSummary,
    setVersion,
    user
  ]);

  const initialize = useSafeCallback(async () => {
    await Promise.all([getFacePhotoDownloadURL(), fetchContractUsageAndAccess()]);
  }, [getFacePhotoDownloadURL, fetchContractUsageAndAccess]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleBackButtonClicked = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_HOME);
  }, [openBasePath]);

  const handleFacePhotoChanged = useSafeCallback(
    async (file: File | null): Promise<void> => {
      if (!file) {
        setErrorMessage(QFACE_SET_ERROR);
        enqueueSnackbar(QFACE_SET_ERROR, { variant: ERROR });
        return;
      }
      const facePhotoURL = await ImageService.putImageFileToStorage(file);

      const request = builder<UpdateUserQFaceRequest>()
        .userId(user.userId)
        .faceRecognitionPhotoURL(facePhotoURL)
        .build();

      const response = await commonRequest<UpdateUserQFaceRequest, UpdateUserQFaceResponse>(UPDATE_USER_QFACE, request);

      if (!response.userId) {
        setErrorMessage(QFACE_SET_ERROR);
        enqueueSnackbar(QFACE_SET_ERROR, { variant: ERROR });
        return;
      }

      setErrorMessage(EMPTY);
      setFaceRecognitionPhotoURL(facePhotoURL);
      setUserAndPropagate(Object.assign(user, { faceRecognitionPhotoURL: facePhotoURL }));
      enqueueSnackbar(QFACE_SET_SAVED, { variant: SUCCESS });
    },
    [commonRequest, enqueueSnackbar, setErrorMessage, setFaceRecognitionPhotoURL, setUserAndPropagate, user]
  );

  const backToProfile = useSafeCallback((): void => {
    setErrorMessage(EMPTY);
    setIsQFaceEditable(false);
  }, [setErrorMessage, setIsQFaceEditable]);

  const membershipName = useMemo<Name | undefined>(() => {
    const memberships = getBasePICV2(user, base, AuthorityDefCategory.RANK);

    if (hasLength(memberships)) {
      return memberships[FIRST_INDEX].authority?.authorityName;
    }

    return undefined;
  }, [base, user]);

  const openSignOut = useSafeCallback((): void => {
    openBasePath(Path.SIGN_OUT);
  }, [openBasePath]);

  const openTermOfUse = useSafeCallback((): void => {
    window.open(TERMS_OF_USE_FOR_KNOT_PLACE, 'newtab');
  }, []);

  const openPrivacyPolicy = useSafeCallback((): void => {
    window.open(PRIVACY_POLICY_FOR_KNOT_PLACE, 'newtab');
  }, []);

  const openAccountPointUsageLogScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_POINT_USAGE_LOGS);
  }, [openBasePath]);

  return (
    <Component
      loading={!loaded}
      style={{ width: '100%', height: '100%' }}
      className='account-profile'
      title={'マイページ'}
    >
      <Container>
        <Content>
          <HeaderWrapper>
            <BackButtonV2 label='ホーム' onClick={handleBackButtonClicked} />
            <PageHeaderWrapper>
              <PageHeaderV2 title='マイページ' />
            </PageHeaderWrapper>
          </HeaderWrapper>
          {isQFaceEditable && (
            <QFaceConfigWrapper>
              <QFaceConfig>
                <ConfigLabel>現在設定されている顔認証用の写真</ConfigLabel>

                <QFaceWrapper>
                  <Skeleton style={styleForPhoto} src={facePhotoDownloadURL} />

                  <AddPhotoButton>
                    <HiddenInputFile
                      type='file'
                      accept='image/*'
                      onChange={event => handleFacePhotoChanged(event.target.files ? event.target.files[ZERO] : null)}
                    />
                    <CustomInstagramIcon />
                    <CustomAddIcon />
                  </AddPhotoButton>
                </QFaceWrapper>
              </QFaceConfig>

              {errorMessage && (
                <MessageWrapper>
                  <MessageLabel>{errorMessage}</MessageLabel>
                  <MessageLabel>写真を再度アップロードしてください。</MessageLabel>
                </MessageWrapper>
              )}

              <BackButtonWrapper>
                <Button type='secondary' onClick={backToProfile}>
                  <BackButtonLabel>戻る</BackButtonLabel>
                </Button>
              </BackButtonWrapper>
            </QFaceConfigWrapper>
          )}

          {!isQFaceEditable && (
            <ProfileWrapper>
              <Profile>
                <ProfileTitle>アカウント</ProfileTitle>
                <ProfileCard>
                  <ImageWrapper>
                    <Image src={user.photoURL || mojaco} />
                  </ImageWrapper>

                  <BaseNameWrapper>
                    <BaseNameLabel>{`${base.baseName}拠点`}</BaseNameLabel>
                  </BaseNameWrapper>

                  <UserNameWrapper>
                    <UserNameLabel>{`${user.familyName} ${user.firstName}`}</UserNameLabel>
                  </UserNameWrapper>

                  {membershipName && (
                    <MembershipNameWrapper>
                      <MembershipLabel>{`${membershipName}`}</MembershipLabel>
                    </MembershipNameWrapper>
                  )}
                </ProfileCard>
              </Profile>

              {useFaceAccess && (
                <ConfigButton onClick={() => setIsQFaceEditable(true)}>
                  <ButtonLabel>顔認証用の写真を設定する</ButtonLabel>
                  <CustomNavigateNextIcon />
                </ConfigButton>
              )}
            </ProfileWrapper>
          )}

          {contractPlanLabel && (
            <>
              <UsageTitle>ご利用状況</UsageTitle>
              <UsageCard>
                <CardLabel>ご契約プラン</CardLabel>
                <CardText>{contractPlanLabel}</CardText>
              </UsageCard>
            </>
          )}
          {usageSummary && (
            <UsageCard>
              <CardLabel>今月のご利用時間 / 残り時間</CardLabel>
              <CardText>
                {toTimeStr(usageSummary.usageMsec, Language.JAPANESE)} /{' '}
                {toTimeStr(
                  isGreaterThanZero(usageSummary.remainingMsec) ? usageSummary!.remainingMsec : ZERO,
                  Language.JAPANESE
                )}
              </CardText>
            </UsageCard>
          )}
          {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) && (
            <UsageCard>
              <CardLabel>利用可能ポイント</CardLabel>
              <CardRow>
                <CardText>{totalUserPoint.toLocaleString()} ポイント</CardText>
                <CustomLinkButton
                  label='利用履歴'
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={openAccountPointUsageLogScreen}
                />
              </CardRow>
            </UsageCard>
          )}

          {hasLength(pastAccesses) && (
            <>
              <AccessTitle>エントランス入退履歴</AccessTitle>
              <ListV2
                rows={pastAccesses.map(access =>
                  builder<ListRow>()
                    .text(toDateTimeStr(access.operationDatetime, Language.JAPANESE))
                    .secondaryAction(
                      access.direction === Direction.ENTER ? <EnterText>入室</EnterText> : <Text>退室</Text>
                    )
                    .build()
                )}
                paginateProps={{ offset, limit: LIMIT, totalCount, setOffset }}
              />
            </>
          )}
        </Content>
        <Footer>
          <SignOutButtonWrapper>
            <SignOutButton label='ログアウト' onClick={() => setIsModalOpen(true)} />
            <AccountDeletionButton label='アカウントの削除' onClick={openAccountDeletion} />
          </SignOutButtonWrapper>
          <FooterButtonWrapper>
            <FooterButton label='利用規約' onClick={openTermOfUse} />
            <FooterButton label='プライバシーポリシー' onClick={openPrivacyPolicy} />
          </FooterButtonWrapper>
        </Footer>
        <DialogV2
          width={280}
          isOpen={isModalOpen}
          headerLabel='ログアウトしますか？'
          buttonsOnTheRight={[
            <ButtonV2 key='cancel' label='いいえ' onClick={() => setIsModalOpen(false)} />,
            <ButtonV2 type='primary' key='sign-out' label='はい' onClick={openSignOut} />
          ]}
          onClose={() => setIsModalOpen(false)}
        />
      </Container>
    </Component>
  );
});

AccountProfile.displayName = 'AccountProfile';
export default AccountProfile;

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  padding: ${themeV2.mixins.v2.spacing}px;
  margin: 0 auto;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;
const PageHeaderWrapper = styled.div`
  margin-top: -${themeV2.mixins.v2.spacing / 2}px;
  margin-left: ${themeV2.mixins.v2.spacing}px;
`;
const QFaceConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
const QFaceConfig = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: ${themeV2.mixins.v2.spacing / 2}px;
`;
const ConfigLabel = styled(Typography)`
  color: ${themeV2.mixins.v2.color.font.lightGray};
  ${themeV2.mixins.v2.typography.body.small};
`;
const QFaceWrapper = styled.div`
  width: 208px;
  height: auto;
  margin: ${themeV2.mixins.v2.spacing * 3}px auto;
  position: relative;
`;
const AddPhotoButton = styled.label`
  width: 40px;
  height: 40px;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  border-radius: 50%;
  right: ${themeV2.mixins.v2.spacing}px;
  bottom: ${themeV2.mixins.v2.spacing}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const HiddenInputFile = styled.input`
  display: none;
`;
const MessageWrapper = styled.div`
  width: 100%;
`;
const MessageLabel = styled(Typography)`
  text-align: center;
  color: ${themeV2.mixins.v2.color.font.pink};
  ${themeV2.mixins.v2.typography.label.medium};
`;
const BackButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${themeV2.mixins.v2.spacing}px;
  * {
    cursor: pointer;
  }
`;
const BackButtonLabel = styled.label`
  ${themeV2.mixins.v2.typography.body.medium};
  margin: 0;
  padding: 0 ${themeV2.mixins.v2.spacing * 4}px;
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
const Profile = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
  margin-top: ${themeV2.mixins.v2.spacing}px;
  margin-left: ${themeV2.mixins.v2.spacing}px;
`;

const ProfileTitle = styled(Title)``;
const UsageTitle = styled(Title)``;
const AccessTitle = styled(Title)``;

const Card = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const ProfileCard = styled(Card)`
  align-items: center;
`;
const UsageCard = styled(Card)``;

const CardLabel = styled.div`
  ${themeV2.mixins.v2.typography.title.small};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomLinkButton = styled(ButtonV2)`
  && {
    ${themeV2.mixins.v2.typography.body.large};
    color: ${themeV2.mixins.v2.color.font.pink};
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 60px;
`;
const BaseNameWrapper = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
`;
const BaseNameLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;
const UserNameWrapper = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;
const UserNameLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.headLine.small};
  color: ${themeV2.mixins.v2.color.font.black};
`;
const MembershipNameWrapper = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  border-radius: 24px;
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
`;
const MembershipLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;
const ConfigButton = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
`;
const ButtonLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
`;
const CustomInstagramIcon = styled(InstagramIcon)`
  color: ${themeV2.mixins.v2.color.font.white};
  ${themeV2.mixins.v2.typography.body.large};
`;
const CustomAddIcon = styled(AddIcon)`
  color: ${themeV2.mixins.v2.color.font.white};
  ${themeV2.mixins.v2.typography.body.large};
  margin-left: -4px;
`;
const CustomNavigateNextIcon = styled(NavigateNextIcon)`
  margin-left: auto;
`;
const styleForPhoto: CSSProperties = {
  width: 208,
  minHeight: 208,
  background: themeV2.mixins.v2.color.background.lightGray,
  objectFit: 'contain',
  borderRadius: `${themeV2.mixins.v2.spacing * 2}px`
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignOutButtonWrapper = styled(ButtonWrapper)`
  gap: ${themeV2.mixins.v2.spacing}px;
  margin: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const FooterButtonWrapper = styled(ButtonWrapper)`
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 15}px;
  background-color: ${themeV2.mixins.v2.color.background.white};
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Text = styled(ListItemText)`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const CardText = styled(Text)``;

const EnterText = styled(Text)`
  color: ${themeV2.mixins.v2.color.font.pink};
`;

const StyledButton = styled(ButtonV2).attrs(() => ({
  isFullWidth: true,
  size: 'large'
}))`
  && {
    max-width: ${MOBILE_MAX_WIDTH}px;
  }
`;

const SignOutButton = styled(StyledButton)`
  && {
    color: ${themeV2.mixins.v2.color.font.gray};
  }
`;
const AccountDeletionButton = styled(StyledButton)`
  && {
    color: ${themeV2.mixins.v2.color.status.error};
  }
`;

const FooterButton = styled(StyledButton)`
  && {
    ${themeV2.mixins.v2.typography.body.large};
    color: ${themeV2.mixins.v2.color.font.black};
  }
`;

const Footer = styled.div`
  width: 100%;
  margin-top: auto;
`;
