import {
  CheckBoxV2,
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelV2,
  RadioBoxV2,
  SelectBoxV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, UserDiv, UserDivId, UserInflowSource, UserInflowSourceId } from '@atomica.co/irori';
import { Email, Gap, Label, Name, Password, Phone, Text } from '@atomica.co/types';
import { EMPTY, PREFECTURE_NAMES, Prefecture, hasLength, isUndefined } from '@atomica.co/utils';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import {
  PRIVACY_POLICY_FOR_KNOT_PLACE,
  PRIVACY_POLICY_FOR_WORKHUB,
  TERMS_OF_USE_FOR_KNOT_PLACE,
  TERMS_OF_USE_FOR_WORKHUB
} from '../../../constants/account-const';
import useUser from '../../../redux/hooks/useUser';
import {
  toInflowSourceLabels,
  toInflowSourceOptions,
  toUserDivLabels,
  toUserDivOptions
} from '../../../utils/user-util';

export interface AccountInputValues {
  familyName: Name;
  firstName: Name;
  email: Email;
  dateOfBirth: Date;
  phone: Phone;
  password: Password;
  prefecture: Prefecture | undefined;
  userDiv: UserDiv | undefined;
  userInflowSource: UserInflowSource | undefined;
  isTermsOfUseForKnotPLACEAgreed: boolean;
  isTermsOfUseForWorkhubAgreed: boolean;
}

export interface AccountInputOnChanges {
  setFamilyName: (familyName: Name) => void;
  setFirstName: (firstName: Name) => void;
  setEmail: (email: Email) => void;
  setDateOfBirth: (dateOfBirth: Date) => void;
  setPhone: (phone: Phone) => void;
  setPassword: (password: Password) => void;
  setPrefecture: (prefecture: Prefecture) => void;
  setUserDiv: (userDiv: UserDiv) => void;
  setUserInflowSource: (userInflowSource: UserInflowSource) => void;
  setIsTermsOfUseForKnotPLACEAgreed: (isTermOfUseForKnotPLACEAgreed: boolean) => void;
  setIsTermsOfUseForWorkhubAgreed: (isTermOfUseForWorkhubAgreed: boolean) => void;
}

export interface IsValidAccountInputValues {
  isValidFamilyName: boolean;
  isValidFirstName: boolean;
  isValidEmail: boolean;
  isValidDateOfBirth: boolean;
  isValidPhone: boolean;
  isFilledPassword: boolean;
  isRegexValidPassword: boolean;
  isTermsOfUseChecked: boolean;
}

interface P {
  base: BaseDto;
  userDivs: UserDiv[];
  userInflowSources: UserInflowSource[];
  values: AccountInputValues;
  onChanges: AccountInputOnChanges;
  isValidValues: IsValidAccountInputValues;
}

const getErrorMessage = (isValid: boolean, label: Label): Text => (isValid ? EMPTY : `${label}を入力してください。`);

const getErrorMessageElement = (label: Label, onClick: (value: boolean) => void): JSX.Element => (
  <div>
    ・
    <LinkWithUnderline
      onClick={() => {
        onClick(true);
      }}
    >
      {label}
    </LinkWithUnderline>
    を入力してください。
  </div>
);

const openTermOfUseForKnotPLACE = (): void => {
  window.open(TERMS_OF_USE_FOR_KNOT_PLACE, 'termsOfUseForKnotPLACE');
};

const openPrivacyPolicyForKnotPLACE = (): void => {
  window.open(PRIVACY_POLICY_FOR_KNOT_PLACE, 'privacyPolicyForKnotPLACE');
};

const openTermOfUseForWorkhub = (): void => {
  window.open(TERMS_OF_USE_FOR_WORKHUB, 'termsOfUseForWorkhub');
};

const openPrivacyPolicyForWorkhub = (): void => {
  window.open(PRIVACY_POLICY_FOR_WORKHUB, 'privacyPolicyForWorkhub');
};

const RegisterAccountInput: React.FC<P> = React.memo(props => {
  const { base, userDivs, userInflowSources, values, onChanges, isValidValues } = props;
  const {
    familyName,
    firstName,
    email,
    password,
    dateOfBirth,
    phone,
    prefecture,
    userDiv,
    userInflowSource,
    isTermsOfUseForKnotPLACEAgreed,
    isTermsOfUseForWorkhubAgreed
  } = values;
  const {
    setFamilyName,
    setFirstName,
    setEmail,
    setPassword,
    setDateOfBirth,
    setPhone,
    setPrefecture,
    setUserDiv,
    setUserInflowSource,
    setIsTermsOfUseForKnotPLACEAgreed,
    setIsTermsOfUseForWorkhubAgreed
  } = onChanges;
  const {
    isValidFamilyName,
    isValidFirstName,
    isValidEmail,
    isValidDateOfBirth,
    isValidPhone,
    isFilledPassword,
    isRegexValidPassword,
    isTermsOfUseChecked
  } = isValidValues;

  const { isLineUser } = useUser();

  const unmountRef = useUnmountRef();
  const [isFamilyNameFocused, setIsFamilyNameFocused] = useSafeState<boolean>(unmountRef, false);
  const [isFirstNameFocused, setIsFirstNameFocused] = useSafeState<boolean>(unmountRef, false);
  const [isEmailFocused, setIsEmailFaFocused] = useSafeState<boolean>(unmountRef, false);
  const [isDateOfBirthFocused, setIsDateOfBirthFocused] = useSafeState<boolean>(unmountRef, false);
  const [isPhoneFocused, setIsPhoneFocused] = useSafeState<boolean>(unmountRef, false);
  const [isPasswordFocused, setIsPasswordFocused] = useSafeState<boolean>(unmountRef, false);

  const termsOfUseRef = useRef<HTMLDivElement>(null);

  const hasInvalidValue = useMemo<boolean>(
    () => Object.values(isValidValues).some(isValid => !isValid),
    [isValidValues]
  );

  const getErrorMessageElementForTermsOfUse = useSafeCallback(
    (label: Label): JSX.Element => (
      <div>
        ・
        <LinkWithUnderline
          onClick={() => {
            if (!termsOfUseRef.current) return;
            termsOfUseRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          {label}
        </LinkWithUnderline>
        に同意してください。
      </div>
    ),
    [termsOfUseRef]
  );

  const handleUserDivChanged = useSafeCallback(
    (userDivId: UserDivId): void => {
      const userDiv = userDivs.find(div => div.userDivId === userDivId);
      if (userDiv) setUserDiv(userDiv);
    },
    [userDivs, setUserDiv]
  );

  const handleUserInflowSourceChanged = useSafeCallback(
    (userInflowSourceId: UserInflowSourceId): void => {
      const userInflowSource = userInflowSources.find(src => src.userInflowSourceId === userInflowSourceId);
      if (userInflowSource) setUserInflowSource(userInflowSource);
    },
    [userInflowSources, setUserInflowSource]
  );

  return (
    <Container>
      <Content>
        <Message>
          入力する情報は、knotPLACE および workhub Pass の双方で使用され、2つのアカウントが作成されます。
        </Message>
        {hasInvalidValue && (
          <Card gap={0}>
            <ErrorMessage>
              <WarningRoundedIcon />
              入力エラーがあります。
            </ErrorMessage>
            {!isValidFamilyName && getErrorMessageElement('姓', setIsFamilyNameFocused)}
            {!isValidFirstName && getErrorMessageElement('名', setIsFirstNameFocused)}
            {!isValidEmail && getErrorMessageElement('メールアドレス', setIsEmailFaFocused)}
            {!isValidDateOfBirth && getErrorMessageElement('誕生日', setIsDateOfBirthFocused)}
            {!isValidPhone && getErrorMessageElement('電話番号', setIsPhoneFocused)}
            {(!isFilledPassword && getErrorMessageElement('workhub Pass のパスワード', setIsPasswordFocused)) ||
              (!isRegexValidPassword && (
                <div>
                  ・<LinkWithUnderline onClick={() => setIsPasswordFocused(true)}>パスワード</LinkWithUnderline>
                  は8文字以上12文字以下の半角英数、半角記号で入力してください。
                </div>
              ))}

            {!isTermsOfUseChecked &&
              !isTermsOfUseForKnotPLACEAgreed &&
              getErrorMessageElementForTermsOfUse('knotPLACEのご利用規約およびプライバシーポリシー')}
            {!isTermsOfUseChecked &&
              !isTermsOfUseForWorkhubAgreed &&
              getErrorMessageElementForTermsOfUse('workhubのご利用規約およびプライバシーポリシー')}
          </Card>
        )}
        <Card>
          <CardHeading>ユーザー情報</CardHeading>
          <InputWithLabelV2
            required
            focusAndScroll={isFamilyNameFocused}
            errorMessage={getErrorMessage(isValidFamilyName, '姓')}
            text='姓'
            value={familyName}
            onChange={setFamilyName}
            onBlur={() => setIsFamilyNameFocused(false)}
          />
          <InputWithLabelV2
            required
            focusAndScroll={isFirstNameFocused}
            errorMessage={getErrorMessage(isValidFirstName, '名')}
            text='名'
            value={firstName}
            onChange={setFirstName}
            onBlur={() => setIsFirstNameFocused(false)}
          />
          <InputWithLabelV2
            required
            inputMode='email'
            readonly={!isLineUser}
            focusAndScroll={isEmailFocused}
            errorMessage={getErrorMessage(isValidEmail, 'メールアドレス')}
            text='メールアドレス'
            value={email}
            onChange={setEmail}
            onBlur={() => setIsEmailFaFocused(false)}
          />
          <InputDateWithLabelV2
            required
            openTo='year'
            scroll={isDateOfBirthFocused}
            errorMessage={getErrorMessage(isValidDateOfBirth, '誕生日')}
            text='誕生日'
            value={dateOfBirth}
            onChange={setDateOfBirth}
          />
          <InputWithLabelV2
            required
            inputMode='tel'
            inputType='number'
            focusAndScroll={isPhoneFocused}
            errorMessage={getErrorMessage(isValidPhone, '電話番号')}
            text='電話番号'
            value={phone}
            onChange={setPhone}
            onBlur={() => setIsPhoneFocused(false)}
          />
          <InputWithLabelV2
            required
            focusAndScroll={isPasswordFocused}
            errorMessage={
              getErrorMessage(isFilledPassword, 'workhub Pass のパスワード') ||
              (isRegexValidPassword ? EMPTY : 'パスワードは8文字以上12文字以下の半角英数、半角記号で入力してください。')
            }
            inputType='password'
            text='workhub Pass のパスワード'
            remarks='パスワードは8文字以上12文字以下の半角英数、半角記号で入力してください。'
            value={password}
            onChange={setPassword}
            onBlur={() => setIsPasswordFocused(false)}
          />
        </Card>
        <Card>
          <CardHeading>アンケート（任意）</CardHeading>
          <div>
            <LabelV2 text='どちらからお越しですか？' />
            <SelectBoxV2
              options={Object.values(Prefecture)}
              labels={PREFECTURE_NAMES}
              value={prefecture}
              onChange={setPrefecture}
            />
          </div>
          {hasLength(userDivs) && (
            <div>
              <LabelV2 text='あてはまるものをお選びください' />
              <RadioBoxV2
                options={toUserDivOptions(userDivs)}
                labels={toUserDivLabels(userDivs)}
                value={userDiv ? userDiv.userDivId : undefined}
                onChange={handleUserDivChanged}
              />
            </div>
          )}
          {hasLength(userInflowSources) && (
            <div>
              <LabelV2 text={`${base.baseName} をどこで知りましたか？`} />
              <RadioBoxV2
                options={toInflowSourceOptions(userInflowSources)}
                labels={toInflowSourceLabels(userInflowSources)}
                value={userInflowSource ? userInflowSource.userInflowSourceId : undefined}
                onChange={handleUserInflowSourceChanged}
              />
            </div>
          )}
        </Card>
        <Card>
          <HeadingWrapper>
            <CardHeading>利用規約・プライバシーポリシー</CardHeading>
            <SubHeading>アカウントを作成するには、同意が必須です。</SubHeading>
          </HeadingWrapper>
          {!isTermsOfUseChecked && (
            <ErrorMessageCard>
              <WarningRoundedIcon />
              すべて同意してください。
            </ErrorMessageCard>
          )}
          <CheckBoxV2
            checked={isTermsOfUseForKnotPLACEAgreed}
            onChange={() => setIsTermsOfUseForKnotPLACEAgreed(!isTermsOfUseForKnotPLACEAgreed)}
          >
            <div ref={termsOfUseRef}>
              knotPLACEの<Link onClick={openTermOfUseForKnotPLACE}>ご利用規約</Link>および
              <Link onClick={openPrivacyPolicyForKnotPLACE}>プライバシーポリシー</Link>に同意する
            </div>
          </CheckBoxV2>
          <CheckBoxV2
            checked={isTermsOfUseForWorkhubAgreed}
            onChange={() => setIsTermsOfUseForWorkhubAgreed(!isTermsOfUseForWorkhubAgreed)}
          >
            <div>
              workhubの<Link onClick={openTermOfUseForWorkhub}>ご利用規約</Link>および
              <Link onClick={openPrivacyPolicyForWorkhub}>プライバシーポリシー</Link>に同意する
            </div>
          </CheckBoxV2>
        </Card>
      </Content>
    </Container>
  );
});

RegisterAccountInput.displayName = 'RegisterAccountInput';
export default RegisterAccountInput;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Message = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const Card = styled.div<{ gap?: Gap }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (!isUndefined(gap) ? `${gap}px` : `${themeV2.mixins.v2.spacing * 2}px`)};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 12px;
  background: ${themeV2.mixins.v2.color.background.white};
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const CardHeading = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const ErrorMessage = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  color: ${themeV2.mixins.v2.color.status.error};
`;

const SubHeading = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Link = styled.div`
  display: inline;
  color: ${themeV2.mixins.v2.color.font.pink};
  cursor: pointer;
`;

const LinkWithUnderline = styled(Link)`
  text-decoration-line: underline;
`;

const ErrorMessageCard = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
  border-radius: 8px;
  color: ${themeV2.mixins.v2.color.status.error};
  background: ${themeV2.mixins.v2.color.background.pinkPale};
`;
