import {
  ButtonV2,
  CheckBoxV2,
  ColWidth,
  Component,
  Header,
  PAGINATE_HEIGHT,
  PageHeaderV2,
  PullDownItemsV2,
  PullDownOption,
  PullDownV2,
  SearchListV2,
  StatusV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseResourceCategoryId,
  PaymentMethod,
  SEARCH_SPACE_RESERVATIONS_FOR_ADMIN,
  SearchSpaceReservationsForAdminRequest,
  SearchSpaceReservationsForAdminResponse,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationMembersForSearching,
  SpaceReservationPaymentStatus,
  SpaceReservationSortColumn,
  SpaceReservationStatus,
  SpaceReservationStatusForSearching,
  UPDATE_SPACE_RESERVATION_STATUS_FOR_ADMIN,
  UpdateSpaceReservationStatusForAdminRequest,
  UpdateSpaceReservationStatusForAdminResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Count, DateStr, Index, Label, NoStr, Offset, Sort, Word } from '@atomica.co/types';
import { EMPTY, ZERO, builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import { Popper } from '@material-ui/core';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import DefaultModal from '../../components/modal/DefaultModal';
import { SearchOption } from '../../enums/event-v2-enum';
import { SpaceReservationActionEnum } from '../../enums/space-enum';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { RESERVATION_PAYMENT_STATUS_LABELS } from '../../texts/reservation-payment-status-text';
import {
  SPACE_RESERVATION_ACTION_LABEL,
  SPACE_RESERVATION_MEMBER_FOR_SEARCHING_LABEL,
  SPACE_RESERVATION_STATUS_FOR_SEARCHING_LABEL
} from '../../texts/space-text';
import {
  isReservationTimeFinished,
  toPaymentStatusFromSpaceReservation,
  toStatusFromSpaceReservation,
  toStatusLabelFromSpaceReservation
} from '../../utils/space-reservation-util';
import { toFullName } from '../../utils/user-util';

const ALL_CATEGORY = 'all';

const LIMIT = 50;

const DEFAULT_COLUMN_WIDTH: ColWidth<ReservationRow> = {
  status: 160,
  reservationNo: 100,
  name: 180,
  date: 160,
  createdUser: 128,
  createdAt: 140,
  paymentAmount: 100
};

const DEFAULT_COLUMN_WIDTH_INCLUDE_PAYMENT_STATUS: ColWidth<ReservationRowIncludePaymentStatus> = {
  status: 160,
  paymentStatus: 112,
  reservationNo: 100,
  name: 180,
  date: 160,
  createdUser: 128,
  createdAt: 140,
  paymentAmount: 100
};

const DEFAULT_HEADER: Header<ReservationRow> = {
  status: { label: '予約ステータス' },
  reservationNo: { label: '予約番号' },
  name: { label: '予約対象（カテゴリー）' },
  date: { label: '利用日時' },
  createdUser: { label: '予約者' },
  createdAt: { label: '予約日時' },
  paymentAmount: { label: '利用料金' }
};

interface ReservationRow {
  id: SpaceReservationId;
  status: JSX.Element;
  reservationNo: NoStr;
  name?: JSX.Element;
  date: JSX.Element;
  createdUser: JSX.Element | typeof EMPTY;
  createdAt: DateStr;
  paymentAmount: string | number;
}

const DEFAULT_HEADER_INCLUDE: Header<ReservationRowIncludePaymentStatus> = {
  status: { label: '予約ステータス' },
  paymentStatus: { label: '支払状況' },
  reservationNo: { label: '予約番号' },
  name: { label: '予約対象（カテゴリー）' },
  date: { label: '利用日時' },
  createdUser: { label: '予約者' },
  createdAt: { label: '予約日時' },
  paymentAmount: { label: '利用料金' }
};

interface ReservationRowIncludePaymentStatus {
  id: SpaceReservationId;
  status: JSX.Element;
  paymentStatus: JSX.Element | typeof EMPTY;
  reservationNo: NoStr;
  name?: JSX.Element;
  date: JSX.Element;
  createdUser: JSX.Element | typeof EMPTY;
  createdAt: DateStr;
  paymentAmount: string | number;
}

interface P {
  base: BaseDto;
  user: User;
}

// // あとでirori-typesにenum定義する => SpaceReservationSortColumnの対象カラムが間違えてる気がするので修正（柳葉さんにどちらの値が正か聞く）
// enum ReservationSortColumn {
//   CREATED_AT = 'bs_space_reservations.createdAt',
//   RESERVATION_NO = 'bs_space_reservations.reservationNo'
// }
const SORT_COLUMNS = {
  createdAt: SpaceReservationSortColumn.CREATED_AT,
  reservationNo: SpaceReservationSortColumn.SPACE_RESERVATION_NO,
  date: SpaceReservationSortColumn.USAGE_START_DATE
};

const statusPullDownOptions = Object.entries(SPACE_RESERVATION_STATUS_FOR_SEARCHING_LABEL).map(([key, value]) =>
  builder<PullDownOption>().id(key).label(value).build()
);

const actionPullDownOptions = Object.entries(SPACE_RESERVATION_ACTION_LABEL).map(([key, value]) =>
  builder<PullDownOption>()
    .id(key)
    .label(value)
    .type(key === SpaceReservationActionEnum.REJECT ? 'error' : 'default')
    .build()
);

const reservationMemberPullDownOptions = Object.entries(SPACE_RESERVATION_MEMBER_FOR_SEARCHING_LABEL).map(
  ([key, value]) => builder<PullDownOption>().id(key).label(value).build()
);

const getDateText = (reservation: SpaceReservation): JSX.Element => {
  return (
    <div>
      {reservation.startAt ? format(reservation.startAt, 'yyyy/MM/dd HH:mm') : EMPTY}〜
      <br />
      {reservation.endAt ? format(reservation.endAt, 'yyyy/MM/dd HH:mm') : EMPTY}
    </div>
  );
};

const getNameText = (reservation: SpaceReservation): JSX.Element => {
  const spaceName = reservation.space!.spaceName;
  return (
    <div>
      {spaceName}
      <br /> {reservation.space?.baseResourceCategory?.categoryLabel}
    </div>
  );
};

const getCreatedUserText = (reservation: SpaceReservation): JSX.Element | typeof EMPTY => {
  const user = reservation.createdUser;
  const companyName = user?.companyName || EMPTY;
  return user ? (
    <div>
      {toFullName(user)}
      <br />
      {companyName}
    </div>
  ) : (
    EMPTY
  );
};

const ReservationListScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { openPathInNewTab } = usePath();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, 0);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [selectedStatus, setSelectedStatus] = useSafeState<SpaceReservationStatusForSearching>(
    unmountRef,
    SpaceReservationStatusForSearching.ALL
  );
  const [selectedResourceCategoryId, setSelectedResourceCategoryId] = useSafeState<BaseResourceCategoryId>(
    unmountRef,
    ALL_CATEGORY
  );
  const [selectedReservationIds, setSelectedReservationIds] = useSafeState<SpaceReservationId[]>(unmountRef, []);
  const [isSelectedAll, setIsSelectedAll] = useSafeState<boolean>(unmountRef, false);
  const [sortKey, setSortKey] = useSafeState<SpaceReservationSortColumn>(
    unmountRef,
    SpaceReservationSortColumn.CREATED_AT
  );
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.DESC);

  const [isPullDownOpen, setIsPullDownOpen] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [modalType, setModalType] = useSafeState<SpaceReservationActionEnum>(
    unmountRef,
    SpaceReservationActionEnum.CONFIRM
  );
  const [reservations, setReservations] = useSafeState<SpaceReservation[]>(unmountRef, []);

  const [selectedReservationMember, setSelectedReservationMember] = useSafeState<SpaceReservationMembersForSearching>(
    unmountRef,
    SpaceReservationMembersForSearching.ALL
  );

  const [selectedReservationPaymentStatus, setSelectedReservationPaymentStatus] = useSafeState<
    SpaceReservationPaymentStatus | 'all'
  >(unmountRef, 'all');

  const [selectedPaymentMethod] = useSafeState<PaymentMethod | 'all'>(unmountRef, 'all');

  const [isCheckedUsageStartDate, setIsCheckedUsageStartDate] = useSafeState<boolean>(unmountRef, true);

  const [isCheckedReservationFromTodayOnwards, setIsCheckedReservationFromTodayOnwards] = useSafeState<boolean>(
    unmountRef,
    false
  );

  const usePaymentStatus = useSafeCallback((): boolean => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT);
  }, [base]);

  const ref = useRef<HTMLDivElement>(null);

  const rows = useMemo<ReservationRow[] | ReservationRowIncludePaymentStatus[]>(() => {
    return reservations.map(reservation => {
      // FIXME: paymentStatusへの移行が完了次第、なくなる想定
      if (!usePaymentStatus()) {
        const row = builder<ReservationRow>()
          .id(reservation.spaceReservationId)
          .status(
            <StatusV2
              width={144}
              status={toStatusFromSpaceReservation(reservation)}
              label={toStatusLabelFromSpaceReservation(reservation)}
            />
          )
          .reservationNo(reservation.reservationNo)
          .name(getNameText(reservation))
          .date(getDateText(reservation))
          .createdUser(getCreatedUserText(reservation))
          .createdAt(format(reservation.createdAt!, 'yyyy/MM/dd HH:mm:ss'))
          .paymentAmount(reservation.pricePerHour ? reservation.pricePerHour.toLocaleString() + '円' : '0円')
          .build();
        return row;
      }

      const row = builder<ReservationRowIncludePaymentStatus>()
        .id(reservation.spaceReservationId)
        .status(
          <StatusV2
            width={150}
            status={toStatusFromSpaceReservation(reservation)}
            label={toStatusLabelFromSpaceReservation(reservation)}
          />
        )
        .paymentStatus(
          reservation.paymentStatus ? (
            <StatusV2
              width={96}
              status={toPaymentStatusFromSpaceReservation(reservation)}
              label={RESERVATION_PAYMENT_STATUS_LABELS[reservation.paymentStatus]}
            />
          ) : (
            EMPTY
          )
        )
        .reservationNo(reservation.reservationNo)
        .name(getNameText(reservation))
        .date(getDateText(reservation))
        .createdUser(getCreatedUserText(reservation))
        .createdAt(format(reservation.createdAt!, 'yyyy/MM/dd HH:mm'))
        .paymentAmount(reservation.billingAmount ? '¥' + reservation.billingAmount.toLocaleString() : '¥0')
        .build();
      return row;
    });
  }, [reservations, usePaymentStatus]);

  const isUpdatable = useMemo<boolean>(() => {
    const selectedReservations = reservations?.filter(reservation => {
      return hasLength(selectedReservationIds) && selectedReservationIds.includes(reservation.spaceReservationId);
    });
    switch (modalType) {
      case SpaceReservationActionEnum.CONFIRM:
        return selectedReservations.every(
          reservation =>
            reservation.status === SpaceReservationStatus.PROVISIONAL && !isReservationTimeFinished(reservation)
        );
      case SpaceReservationActionEnum.REJECT:
        return selectedReservations.every(reservation => !isReservationTimeFinished(reservation));
    }
  }, [modalType, reservations, selectedReservationIds]);

  const resourceCategoryPullDownOptions = useMemo<PullDownOption[]>(() => {
    const options =
      base.baseResourceCategories?.map(category => {
        return builder<PullDownOption>().id(category.baseResourceCategoryId).label(category.categoryLabel).build();
      }) ?? [];
    return [builder<PullDownOption>().id(ALL_CATEGORY).label('すべての設備カテゴリ').build()].concat(options);
  }, [base]);

  // TODO: 会議室周りのステータス整理が完了したのち(spaceReservationPaymentStatus)に、すべてのステータスを適応する
  const reservationPaymentStatusPullDownOptions = useMemo<PullDownOption[]>(() => {
    const options = [
      { key: 'unpaid', label: RESERVATION_PAYMENT_STATUS_LABELS[SpaceReservationPaymentStatus.UNPAID] },
      { key: 'paid', label: RESERVATION_PAYMENT_STATUS_LABELS[SpaceReservationPaymentStatus.PAID] }
    ].map(({ key, label }) => {
      return builder<PullDownOption>().id(key).label(label).build();
    });
    return [builder<PullDownOption>().id('all').label('すべての支払いステータス').build()].concat(options);
  }, []);

  // const reservationPaymentMethodPullDownOptions = useMemo<PullDownOption[]>(() => {
  //   const options = [
  //     { key: 'cash', label: RESERVATION_PAYMENT_METHOD_LABELS[PaymentMethod.CASH].concat('・現金払い') }
  //   ].map(({ key, label }) => builder<PullDownOption>().id(key).label(label).build());
  //   return [builder<PullDownOption>().id('all').label('すべての支払い方法').build()].concat(options);
  // }, []);

  const actionLabel = useMemo<Label>(() => {
    switch (modalType) {
      case SpaceReservationActionEnum.CONFIRM:
        return '確定';
      case SpaceReservationActionEnum.REJECT:
        return '却下';
    }
  }, [modalType]);

  const initialize = useSafeCallback((): void => {
    setOffset(ZERO);
    setIsSelectedAll(false);
    setSelectedReservationIds([]);
  }, [setOffset, setIsSelectedAll, setSelectedReservationIds]);

  const handleSortColClicked = useSafeCallback(
    (sortKey: SpaceReservationSortColumn, sort: Sort): void => {
      initialize();
      setSortKey(sortKey);
      setSort(sort);
      setIsPullDownOpen(false);
    },
    [initialize, setSortKey, setSort, setIsPullDownOpen]
  );

  const searchSpaceReservations = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    setIsPullDownOpen(false);
    const request = builder<SearchSpaceReservationsForAdminRequest>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .offset(offset)
      .word(searchingWord)
      .sortCol(sortKey)
      .sort(sort)
      .status(selectedStatus)
      .baseResourceCategoryId(selectedResourceCategoryId === ALL_CATEGORY ? undefined : selectedResourceCategoryId)
      .paymentStatus(selectedReservationPaymentStatus === 'all' ? undefined : selectedReservationPaymentStatus)
      .paymentMethod(selectedPaymentMethod === 'all' ? undefined : selectedPaymentMethod)
      .reservationMember(selectedReservationMember === 'all' ? undefined : selectedReservationMember)
      .usageStartDate(isCheckedUsageStartDate ? new Date() : undefined)
      .isReservationFromTodayOnwards(isCheckedReservationFromTodayOnwards)
      .build();

    const { spaceReservation, totalCount } = await commonRequest<
      SearchSpaceReservationsForAdminRequest,
      SearchSpaceReservationsForAdminResponse
    >(SEARCH_SPACE_RESERVATIONS_FOR_ADMIN, request);

    setReservations(spaceReservation);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [
    base.baseId,
    commonRequest,
    offset,
    searchingWord,
    selectedPaymentMethod,
    selectedReservationMember,
    selectedReservationPaymentStatus,
    selectedResourceCategoryId,
    selectedStatus,
    setIsLoaderShown,
    setIsPullDownOpen,
    setReservations,
    setTotalCount,
    sort,
    sortKey,
    isCheckedUsageStartDate,
    isCheckedReservationFromTodayOnwards
  ]);

  useEffect(() => {
    searchSpaceReservations();
  }, [searchSpaceReservations]);

  useEffect(() => {
    if (!hasLength(selectedReservationIds)) {
      setIsPullDownOpen(false);
    }
  }, [selectedReservationIds, setIsPullDownOpen]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      initialize();
      setSearchingWord(word);
    },
    [initialize, setSearchingWord]
  );

  const openReservationDetailsScreen = useSafeCallback(
    (index: Index): void => {
      const selectedReservation = rows[index];
      openPathInNewTab(embedIdInPath(Path.RESERVATION_DETAIL, PATH_IDS, [base.baseCode, selectedReservation.id]));
    },
    [base, openPathInNewTab, rows]
  );

  const updateSpaceReservationStatus = useSafeCallback(
    async (status: SpaceReservationStatus): Promise<void> => {
      const request = builder<UpdateSpaceReservationStatusForAdminRequest>()
        .baseId(base.baseId)
        .spaceReservationIds(selectedReservationIds)
        .userId(user.userId)
        .status(status)
        .build();
      const response = await commonRequest<
        UpdateSpaceReservationStatusForAdminRequest,
        UpdateSpaceReservationStatusForAdminResponse
      >(UPDATE_SPACE_RESERVATION_STATUS_FOR_ADMIN, request);

      if (!response || !hasLength(response.spaceReservationIds)) {
        openSnackbar(`予約の${actionLabel}に失敗しました。`, 'error', 3000);
        return;
      }

      await searchSpaceReservations();

      initialize();
      openSnackbar(`${selectedReservationIds.length}件の予約を${actionLabel}しました。`, 'success', 3000);
    },
    [actionLabel, base, commonRequest, openSnackbar, searchSpaceReservations, selectedReservationIds, user, initialize]
  );

  const handlePullDownClicked = useSafeCallback(
    async (action: SpaceReservationActionEnum): Promise<void> => {
      setModalType(action);
      setIsPullDownOpen(false);
      setIsModalOpen(true);
    },
    [setIsModalOpen, setIsPullDownOpen, setModalType]
  );

  const handleModalPrimaryButtonClicked = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    setIsModalOpen(false);
    switch (modalType) {
      case SpaceReservationActionEnum.CONFIRM:
        await updateSpaceReservationStatus(SpaceReservationStatus.CONFIRMED);
        break;
      case SpaceReservationActionEnum.REJECT:
        await updateSpaceReservationStatus(SpaceReservationStatus.REJECTED);
    }
    setIsLoaderShown(false);
  }, [modalType, setIsLoaderShown, setIsModalOpen, updateSpaceReservationStatus]);

  const handleStatusChanged = useSafeCallback(
    (status: SpaceReservationStatusForSearching): void => {
      initialize();
      setSelectedStatus(status);
    },
    [initialize, setSelectedStatus]
  );

  const handleResourceCategoryIdChanged = useSafeCallback(
    (id: BaseResourceCategoryId): void => {
      initialize();
      setSelectedResourceCategoryId(id);
    },
    [initialize, setSelectedResourceCategoryId]
  );

  const handleReservationMemberChanged = useSafeCallback(
    (id: SpaceReservationMembersForSearching): void => {
      initialize();
      setSelectedReservationMember(id);
    },
    [initialize, setSelectedReservationMember]
  );

  const handleReservationStatusChanged = useSafeCallback(
    (id: SpaceReservationPaymentStatus | 'all'): void => {
      initialize();
      setSelectedReservationPaymentStatus(id);
    },
    [initialize, setSelectedReservationPaymentStatus]
  );

  // const handleReservationMethodChanged = useSafeCallback(
  //   (id: PaymentMethod | 'all'): void => {
  //     initialize();
  //     setSelectedPaymentMethod(id);
  //   },
  //   [initialize, setSelectedReservationPaymentStatus]
  // );

  return (
    <Component style={styleForFullExpansion} className='reservation-list-screen'>
      <Container>
        <Content>
          <PageHeaderV2 title='予約' />
          <SearchListV2
            title={`${totalCount}件の予約`}
            name='予約'
            colWidth={usePaymentStatus() ? DEFAULT_COLUMN_WIDTH_INCLUDE_PAYMENT_STATUS : DEFAULT_COLUMN_WIDTH}
            header={usePaymentStatus() ? DEFAULT_HEADER_INCLUDE : DEFAULT_HEADER}
            rows={isLoaderShown ? [] : rows}
            sortKey={sortKey}
            sortCol={SORT_COLUMNS}
            wrapText={true}
            offset={offset}
            limit={LIMIT}
            totalCount={totalCount}
            placeholder='設備名・予約番号・予約者で検索'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            onChange={handleSearchingWordChanged}
            onClickRow={openReservationDetailsScreen}
            onClickSortCol={handleSortColClicked}
            setOffset={setOffset}
            selectedIds={selectedReservationIds}
            isSelectedAll={isSelectedAll}
            setSelectedIds={setSelectedReservationIds}
            setIsSelectedAll={setIsSelectedAll}
            headerBottomComponent={
              <PullDownWrapper>
                <PullDownV2
                  id={selectedStatus}
                  placeholder='選択'
                  options={statusPullDownOptions}
                  onClick={handleStatusChanged}
                />
                <PullDownV2
                  id={selectedResourceCategoryId}
                  placeholder='選択'
                  options={resourceCategoryPullDownOptions}
                  onClick={handleResourceCategoryIdChanged}
                />
                <PullDownV2
                  id={selectedReservationMember}
                  placeholder='選択'
                  options={reservationMemberPullDownOptions}
                  onClick={handleReservationMemberChanged}
                />
                {/* FIXME: 支払い情報の登録が完了したタイミングで対応 */}
                {/* {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT) && (
                  <PullDownV2
                    id={selectedPaymentMethod}
                    options={reservationPaymentMethodPullDownOptions}
                    placeholder='選択'
                    onClick={handleReservationMethodChanged}
                  />
                )} */}

                {usePaymentStatus() && (
                  <PullDownV2
                    id={selectedReservationPaymentStatus}
                    placeholder='選択'
                    options={reservationPaymentStatusPullDownOptions}
                    onClick={handleReservationStatusChanged}
                  />
                )}

                <CheckBoxV2
                  checked={isCheckedUsageStartDate}
                  onChange={() => setIsCheckedUsageStartDate(!isCheckedUsageStartDate)}
                >
                  <span style={{ display: 'inline', whiteSpace: 'nowrap' }}>本日の予約のみ</span>
                </CheckBoxV2>
                <CheckBoxV2
                  checked={isCheckedReservationFromTodayOnwards}
                  onChange={() => setIsCheckedReservationFromTodayOnwards(!isCheckedReservationFromTodayOnwards)}
                >
                  <span style={{ display: 'inline', whiteSpace: 'nowrap' }}>本日以降の予約</span>
                </CheckBoxV2>
              </PullDownWrapper>
            }
            tableRightComponent={
              <div ref={ref}>
                <ButtonV2
                  endIcon={isPullDownOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                  type='tertiary'
                  label='一括操作'
                  onClick={() => setIsPullDownOpen(prev => !prev)}
                />
                <Popper open={isPullDownOpen} anchorEl={ref.current}>
                  <PullDownItemsV2 options={actionPullDownOptions} onClick={handlePullDownClicked} />
                </Popper>
              </div>
            }
          />
        </Content>
        <DefaultModal
          isOpen={isModalOpen}
          headerLabel={`一括で予約を${actionLabel}`}
          rightButtonProps={{
            disabled: isLoaderShown || !isUpdatable,
            label: '確定',
            onClick: handleModalPrimaryButtonClicked
          }}
          onClose={() => setIsModalOpen(false)}
        >
          <div>
            選択中の{selectedReservationIds.length}件の予約を{actionLabel}します
          </div>
          <div>＊選択した予約に関連する予約も{actionLabel}されます</div>
        </DefaultModal>
      </Container>
    </Component>
  );
});

ReservationListScreen.displayName = 'ReservationListScreen';
export default ReservationListScreen;

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const PullDownWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  width: 472px;
  margin: ${themeV2.mixins.v2.spacing}px 0px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
`;
