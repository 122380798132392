import {
  BillingIdV2,
  BillingLogId,
  BillingLogSortColumn,
  BillingLogV2,
  BillingSortColumn,
  BillingV2,
  ContractIdV2,
  ContractLogId,
  ContractLogSortColumn,
  ContractLogV2,
  ContractorType,
  ContractSortColumn,
  ContractStatusV2,
  ContractUserId,
  ContractUsersSortColumn,
  ContractUserV2,
  ContractV2,
  ContractVersion,
  PaymentMethod,
  PaymentOption,
  Sort,
  SpaceReservationSortColumn,
  TaxDiv,
  UnitV2,
  User
} from '@atomica.co/irori';
import { Address, Count, Index, Name } from '@atomica.co/types';
import { builder, EMPTY, hasLength, ONE, PREFECTURE_NAMES, ZERO } from '@atomica.co/utils';
import { isAfter, isBefore } from 'date-fns';
import { ContractV2Dto } from '../__generated/model';
import { DetailForContractDetailScreen, DetailForContractRegisterScreen } from '../converters/contract-v2-converter';
import { ValidateTypeEnum } from '../enums/validate-enum';
import { PlanInputValues } from '../screens/contract-v2/contract-inputs/PlanInput';
import { ValidateContracts } from '../screens/contract-v2/RegisterContractScreen';
import { ConfigValidateColumn, validate, ValidateMessage } from './validate-util';

export interface HeaderCheckBoxStatus {
  isHeaderChecked: boolean;
  isPartialChecked: boolean;
}

type RowData = ContractV2 | ContractUserV2 | ContractLogV2 | BillingV2 | BillingLogV2;
type Id = ContractIdV2 | ContractUserId | ContractLogId | BillingIdV2 | BillingLogId;

export const isValidContract = (contract?: ContractV2, baseDate?: Date | null): boolean => {
  if (!contract || !baseDate) return false;
  const { contractStatus, startDate, endDate } = contract;
  if (contractStatus !== ContractStatusV2.CONFIRMED) return false;
  if (isAfter(startDate, baseDate)) return false;
  if (isBefore(endDate!, baseDate)) return false;
  return true;
};

export const constructHeaderCheckBoxStatus = (rows: RowData[], selectedIds: Id[], key: Id): HeaderCheckBoxStatus => {
  const isCheckedAll = rows.every(row => selectedIds.includes(row[key]));
  const isCheckedSome = rows.some(row => selectedIds.includes(row[key]));

  return builder<HeaderCheckBoxStatus>()
    .isHeaderChecked(isCheckedSome || isCheckedAll)
    .isPartialChecked(!isCheckedAll && isCheckedSome)
    .build();
};

export const updateHeaderSelectedIds = (rows: RowData[], setSelectedIds: (ids) => void, key: Id): void => {
  setSelectedIds(selectedIds => {
    const rowIds = rows.map(row => row[key]);
    return rowIds.every(rowId => selectedIds.includes(rowId))
      ? selectedIds.filter(selected => !rowIds.includes(selected))
      : selectedIds.concat(rowIds.filter(rowId => !selectedIds.includes(rowId)));
  });
};

export const updateRowSelectedIds = (rows: RowData[], setSelectedIds: (ids) => void, key: Id, index: Index): void => {
  setSelectedIds(selectedIds => {
    const rowId = rows[index][key];
    const newSelectedIds = selectedIds.concat();
    newSelectedIds.includes(rowId)
      ? newSelectedIds.splice(newSelectedIds.indexOf(rowId), ONE)
      : newSelectedIds.push(rowId);
    return newSelectedIds;
  });
};

export type SortColumn =
  | ContractSortColumn
  | ContractUsersSortColumn
  | ContractLogSortColumn
  | BillingSortColumn
  | SpaceReservationSortColumn
  | BillingLogSortColumn;

export const updateSortCol = <P>(
  sortCol: P | undefined,
  setSortCol: React.Dispatch<React.SetStateAction<P>>,
  setSort: React.Dispatch<React.SetStateAction<Sort>>
): void => {
  if (!sortCol) return;

  const { ASC, DESC } = Sort;
  setSortCol(prevSortCol => {
    setSort(prevSort => {
      return sortCol !== prevSortCol ? ASC : prevSort === ASC ? DESC : ASC;
    });
    return sortCol;
  });
};

export const findUnit = (units: UnitV2[], detail: DetailForContractRegisterScreen): UnitV2 | undefined => {
  return units.find(unit => unit.unitId === detail.unitId);
};

export const findUnitName = (units: UnitV2[], detail: DetailForContractRegisterScreen): Name => {
  const unit = findUnit(units, detail);
  return unit ? unit.unitName : EMPTY;
};

export const existsContractDetailWithTaxDiv = (details: DetailForContractRegisterScreen[], taxDiv: TaxDiv): boolean => {
  return details.some(detail => detail.taxDiv === taxDiv);
};

const CONFIG_VALIDATE_CONTRACT: ConfigValidateColumn[] = [
  { columnName: 'startDate', itemName: '契約開始日', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'email', itemName: '契約者メールアドレス', validateType: ValidateTypeEnum.EMAIL },
  { columnName: 'contractorPost', itemName: '契約者郵便番号', validateType: ValidateTypeEnum.POST },
  { columnName: 'contractorAddress', itemName: '契約者住所', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'phone', itemName: '契約者電話番号', validateType: ValidateTypeEnum.PHONE_NUMBER }
];
const CONFIG_VALIDATE_CONTRACT_V2: ConfigValidateColumn[] = [
  { columnName: 'startDate', itemName: '契約開始日', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'email', itemName: '契約者メールアドレス', validateType: ValidateTypeEnum.EMAIL },
  { columnName: 'contractorPost', itemName: '契約者郵便番号', validateType: ValidateTypeEnum.POST },
  { columnName: 'contractorPrefecture', itemName: '契約者都道府県', validateType: ValidateTypeEnum.UNDEFINED },
  { columnName: 'contractorCity', itemName: '契約者市区町村', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'contractorAddress', itemName: '契約者番地・建物名等', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'phone', itemName: '契約者電話番号', validateType: ValidateTypeEnum.PHONE_NUMBER }
];

const CONFIG_VALIDATE_REPRESENTATIVE_NAME: ConfigValidateColumn[] = [
  { columnName: 'entityName', itemName: '法人名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'representativeFamilyName', itemName: '代表者姓', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'representativeFirstName', itemName: '代表者名', validateType: ValidateTypeEnum.EMPTY }
];
const CONFIG_VALIDATE_REPRESENTATIVE_NAME_V2: ConfigValidateColumn[] = [
  { columnName: 'entityName', itemName: '法人名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'representativeFamilyName', itemName: '代表者姓', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'representativeFirstName', itemName: '代表者名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'representativeFamilyNameKana', itemName: '代表者姓（カナ）', validateType: ValidateTypeEnum.KATAKANA },
  { columnName: 'representativeFirstNameKana', itemName: '代表者名（カナ）', validateType: ValidateTypeEnum.KATAKANA }
];

const CONFIG_VALIDATE_NAME: ConfigValidateColumn[] = [
  { columnName: 'familyName', itemName: '契約者姓', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'firstName', itemName: '契約者名', validateType: ValidateTypeEnum.EMPTY }
];
const CONFIG_VALIDATE_NAME_V2: ConfigValidateColumn[] = [
  { columnName: 'familyName', itemName: '契約者姓', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'firstName', itemName: '契約者名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'familyNameKana', itemName: '契約者姓（カナ）', validateType: ValidateTypeEnum.KATAKANA },
  { columnName: 'firstNameKana', itemName: '契約者名（カナ）', validateType: ValidateTypeEnum.KATAKANA }
];

const CONFIG_VALIDATE_CONTRACTOR_NAME: ConfigValidateColumn[] = [
  { columnName: 'contractorFamilyName', itemName: '契約先担当者姓', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'contractorFirstName', itemName: '契約先担当者名', validateType: ValidateTypeEnum.EMPTY }
];
const CONFIG_VALIDATE_CONTRACTOR_NAME_V2: ConfigValidateColumn[] = [
  { columnName: 'contractorFamilyName', itemName: '契約先担当者姓', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'contractorFirstName', itemName: '契約先担当者名', validateType: ValidateTypeEnum.EMPTY },
  {
    columnName: 'contractorFamilyNameKana',
    itemName: '契約先担当者姓（カナ）',
    validateType: ValidateTypeEnum.KATAKANA
  },
  {
    columnName: 'contractorFirstNameKana',
    itemName: '契約先担当者名（カナ）',
    validateType: ValidateTypeEnum.KATAKANA
  }
];

const CONFIG_VALIDATE_PAYMENT_COUNT: ConfigValidateColumn = {
  columnName: 'paymentCount',
  itemName: '支払い回数',
  validateType: ValidateTypeEnum.POSITIVE_NUMBER
};

const CONFIG_VALIDATE_BILLING_POST: ConfigValidateColumn[] = [
  {
    columnName: 'billingPost',
    itemName: '請求先郵便番号',
    validateType: ValidateTypeEnum.POST
  },
  { columnName: 'billingAddress', itemName: '請求先住所', validateType: ValidateTypeEnum.EMPTY }
];
const CONFIG_VALIDATE_BILLING_POST_V2: ConfigValidateColumn[] = [
  {
    columnName: 'billingPost',
    itemName: '請求先郵便番号',
    validateType: ValidateTypeEnum.POST
  },
  { columnName: 'billingPrefecture', itemName: '請求先都道府県', validateType: ValidateTypeEnum.UNDEFINED },
  { columnName: 'billingCity', itemName: '請求先市区町村', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'billingAddress', itemName: '請求先番地・建物名等', validateType: ValidateTypeEnum.EMPTY }
];

const CONFIG_VALIDATE_CONTRACT_DETAIL: ConfigValidateColumn[] = [
  { columnName: 'itemName', itemName: '項目名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'taxDiv', itemName: '課税', validateType: ValidateTypeEnum.UNDEFINED },
  { columnName: 'quantity', itemName: '数量', validateType: ValidateTypeEnum.POSITIVE_NUMBER },
  { columnName: 'unitPrice', itemName: '単価', validateType: ValidateTypeEnum.POSITIVE_NUMBER_OR_ZERO }
];

const CONFIG_VALIDATE_CONTRACT_DISCOUNT: ConfigValidateColumn[] = [
  { columnName: 'itemName', itemName: '項目名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'taxDiv', itemName: '課税', validateType: ValidateTypeEnum.UNDEFINED },
  { columnName: 'recurrenceCount', itemName: '割引期間', validateType: ValidateTypeEnum.POSITIVE_NUMBER },
  { columnName: 'unitPrice', itemName: '割引額', validateType: ValidateTypeEnum.NEGATIVE_NUMBER }
];

const CONFIG_VALIDATE_USERS_COUNT: ConfigValidateColumn[] = [
  {
    columnName: 'usersCount',
    itemName: '利用者数',
    validateType: ValidateTypeEnum.POSITIVE_NUMBER
  }
];

export const contractValidater = (contract: ValidateContracts, isUseContractPayment: boolean): ValidateMessage => {
  const configs: ConfigValidateColumn[] = [];

  if (isUseContractPayment) {
    configs.push(...CONFIG_VALIDATE_CONTRACT_V2);
  } else {
    configs.push(...CONFIG_VALIDATE_CONTRACT);
  }
  if (contract.contractorType === ContractorType.ENTITY) {
    if (isUseContractPayment) {
      configs.push(...CONFIG_VALIDATE_REPRESENTATIVE_NAME_V2);
      configs.push(...CONFIG_VALIDATE_CONTRACTOR_NAME_V2);
    } else {
      configs.push(...CONFIG_VALIDATE_REPRESENTATIVE_NAME);
      configs.push(...CONFIG_VALIDATE_CONTRACTOR_NAME);
    }
  } else {
    if (isUseContractPayment) {
      configs.push(...CONFIG_VALIDATE_NAME_V2);
    } else {
      configs.push(...CONFIG_VALIDATE_NAME);
    }
  }
  if (contract.paymentMethod === PaymentMethod.CREDIT_CARD && contract.paymentOption && PaymentOption.INSTALLMENT)
    configs.push(CONFIG_VALIDATE_PAYMENT_COUNT);
  if (
    (contract.paymentMethod === PaymentMethod.INVOICE ||
      (isUseContractPayment && contract.paymentMethod === PaymentMethod.BANK_TRANSFER)) &&
    !contract.isSameBillingAddressAndContractorAddress
  ) {
    if (isUseContractPayment) {
      configs.push(...CONFIG_VALIDATE_BILLING_POST_V2);
    } else {
      configs.push(...CONFIG_VALIDATE_BILLING_POST);
    }
  }

  return validate<ValidateContracts>(contract, configs);
};

export const contractDetailValidater = (contract: PlanInputValues): ValidateMessage => {
  let emptyError: string[] = [];
  let invalidError: string[] = [];

  const targetContractDetails = [...contract.monthlyContractDetails, ...contract.atOnceContractDetails];

  for (const detail of targetContractDetails) {
    if (detail.disabled) continue;
    const { emptyErrorMessage, invalidErrorMessage } = validate<DetailForContractRegisterScreen>(
      detail,
      CONFIG_VALIDATE_CONTRACT_DETAIL
    );
    emptyError = emptyErrorMessage;
    invalidError = invalidErrorMessage;
  }

  for (const discount of contract.discountDetails) {
    if (discount.disabled) continue;
    const { emptyErrorMessage, invalidErrorMessage } = validate<DetailForContractRegisterScreen>(
      discount,
      CONFIG_VALIDATE_CONTRACT_DISCOUNT
    );
    emptyError = emptyErrorMessage;
    invalidError = invalidErrorMessage;
  }

  const { emptyErrorMessage, invalidErrorMessage } = validate<{ usersCount: Count }>(
    { usersCount: contract.usersCount },
    CONFIG_VALIDATE_USERS_COUNT
  );
  emptyError = emptyError.concat(emptyErrorMessage);
  invalidError = invalidError.concat(invalidErrorMessage);

  return {
    emptyErrorMessage: emptyError.filter((str, index) => emptyError.indexOf(str) === index),
    invalidErrorMessage: invalidError.filter((str, index) => invalidError.indexOf(str) === index)
  };
};

export const getContractVersion = (
  user: User,
  contractV2?: ContractV2 | ContractV2Dto
): ContractVersion | undefined => {
  if (user.contract) return ContractVersion.V1;
  if (contractV2) return ContractVersion.V2;
  return undefined;
};

export const getContractFamilyName = (fullName: Name | undefined): Name => {
  if (!fullName) return EMPTY;
  const names = fullName.split(' ');
  return hasLength(names) ? names[ZERO] : EMPTY;
};

export const getContractFirstName = (fullName: Name | undefined): Name => {
  if (!fullName) return EMPTY;
  const names = fullName.split(' ');
  return names.length > ONE ? names[ONE] : EMPTY;
};

export const constructFullContractorAddress = (detail: DetailForContractDetailScreen): Address => {
  const post = detail.contractorPost ? `〒${detail.contractorPost}\n` : EMPTY;
  const prefecture = detail.contractorPrefecture ? PREFECTURE_NAMES[detail.contractorPrefecture] : EMPTY;
  const city = detail.contractorCity ?? EMPTY;
  const address = detail.contractorAddress ?? EMPTY;
  return post + prefecture + city + address;
};
